import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   
    ourLotto: sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo')).lotto
    : null,
}

const lottoSlice = createSlice({
    name: 'lotto',
    initialState,
    reducers: {
        setLotto: (state, action) =>{
            state.ourLotto = action.payload;
        }
    }
})

export const {setLotto} = lottoSlice.actions;

export default lottoSlice.reducer;

