import React, { useEffect, useState } from 'react'

const EndMonthTime1 = ({dd,hh,mm,ss}) => {

    const [timeLeft, setTimeLeft] = useState(0);

    function calculateTimeUntilNextMonth(day, hour, min, sec) {
        const today = new Date();
        const endOfMonth = new Date(today.getFullYear(), today.getMonth(), day,hour, min, sec ,0);
        if(today >= endOfMonth){
            endOfMonth.setMonth(today.getMonth()+1);
        }
        return endOfMonth;
      }
    
  
    useEffect(() => {
        const countdownEndMonth  = calculateTimeUntilNextMonth(dd,hh,mm,ss);
        const now = new Date();
        const difference = countdownEndMonth - now;
        setTimeLeft(difference);

      const interval = setInterval(() => {
        const newDifference = countdownEndMonth - new Date()
        setTimeLeft(newDifference);
      }, 1000);
  
      return () => clearInterval(interval);
    }, [dd,hh,mm,ss]);
  
    function formatTime(time) {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }

  
    // console.log(timeLeft.hours)
  return (
    <>{formatTime(timeLeft)}</>
  )
}

export default EndMonthTime1
