import ppf_logo from "../../images/ppf_logo.jpg";

import goldwing from "../../images/ppf12.png";
import huaystar from "../../images/ppf13.png";
import huaycrown from "../../images/ppf14.png";
import huaykaren from "../../images/ppf15.png";

import cat from "../../images/cat.jpg";
import dog from "../../images/dog.jpg";
import cheetah from "../../images/Cheetah.jpg";
import penguin from "../../images/penguin.jpg";

import elephant from "../../images/elephant.jpg";
import bee from "../../images/bee.jpg";
import fish from "../../images/fish.jpg";
import bull from "../../images/bull.jpg";
import monkey from "../../images/monkey.jpg";
import panda from "../../images/panda.jpg";
import turtle from "../../images/turtle.jpg";

import cow from "../../images/cow.jpg";
import fox from "../../images/fox.jpg";
import hippopo from "../../images/hippopotamus.jpg";
import ladybug from "../../images/ladybug.jpg";

import bear from "../../images/bear.jpg";
import dragon from "../../images/dragon.jpg";
import lion from "../../images/lion.jpg";
import rhinoceros from "../../images/rhinoceros.jpg";

import snake from "../../images/snake.jpg";
import durian from "../../images/durian.jpg";
import grape from "../../images/grape.jpg";
import orange from "../../images/orange.jpg";

import mangosteen from "../../images/mangosteen.jpg";

import watermelon from "../../images/watermelon.jpg";
import banana from "../../images/banana.jpg";
import greenapple from "../../images/greenapple.jpg";
import mango from "../../images/mango.jpg";

import papaya from "../../images/papaya.jpg";
import redapple from "../../images/redapple.jpg";
import cherry from "../../images/cherry.jpg";
import coconut from "../../images/coconut.jpg";

import lemon from "../../images/lemon.jpg";
import pineapple from "../../images/pineapple.jpg";
import strawberry from "../../images/strawberry.jpg";
import cake from "../../images/cake.jpg";

import chocolate from "../../images/chocolate.jpg";
import hamburger from "../../images/hamburger.jpg";
import pizza from "../../images/pizza.jpg";
import sausage from "../../images/sausage.jpg";

import baseball from "../../images/baseball.jpg";
import basketball from "../../images/basketball.jpg";
import bowling from "../../images/bowling.jpg";
import football from "../../images/football.jpg";

import rugby from "../../images/rugby.jpg";
import equipment from "../../images/equipment.jpg";
import hammer from "../../images/hammer.jpg";
import pencil from "../../images/pencil.jpg";

import ruler from "../../images/ruler.jpg";
import scissors from "../../images/scissors.jpg";
import candlestick from "../../images/candlestick.jpg";
import hanginglamp from "../../images/hanginglamp.jpg";

import match from "../../images/match.jpg";
import tablelamp from "../../images/tablelamp.jpg";
import torch from "../../images/torch.jpg";
import coin from "../../images/coin.jpg";

import crown from "../../images/crown.jpg";
import diamond from "../../images/diamond.jpg";
import gold from "../../images/gold.jpg";
import treasurechest from "../../images/treasurechest.jpg";

const images = {
  ppf_logo,
  goldwing,
  huaystar,
  huaycrown,
  huaykaren,
  cat,
  dog,
  cheetah,
  penguin,
  elephant,
  bee,
  fish,
  bull,
  monkey,
  panda,
  turtle,
  cow,
  fox,
  hippopo,
  ladybug,
  bear,
  dragon,
  lion,
  rhinoceros,
  snake,
  durian,
  grape,
  orange,
  mangosteen,
  watermelon,
  banana,
  greenapple,
  mango,
  papaya,
  redapple,
  cherry,
  coconut,
  lemon,
  pineapple,
  strawberry,
  cake,
  chocolate,
  hamburger,
  pizza,
  sausage,
  baseball,
  basketball,
  bowling,
  football,
  rugby,
  equipment,
  hammer,
  pencil,
  ruler,
  scissors,
  candlestick,
  hanginglamp,
  match,
  tablelamp,
  torch,
  coin,
  crown,
  diamond,
  gold,
  treasurechest,
};

export default images;
