import banner from '../images/banner.jpg'

const Banner = () => {
  return (
    <div style={{paddingTop:"50px"}}>
      <img src={banner} alt="" className="banner" />
    </div>
  )
}

export default Banner
