import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bet0: [],
    // bet1: sessionStorage.getItem('userInfo') 
    // ? JSON.parse(sessionStorage.getItem('userInfo')).myBet
    // : null,
    bet2: sessionStorage.getItem('userInfo') 
    ? JSON.parse(sessionStorage.getItem('userInfo')).myBet
    : null,
};
const betSlice = createSlice({
    name: 'bet',
    initialState,
    reducers: {
        setBet: (state,action) =>{
            state.bet2 = action.payload;
        },
        addBet: (state,action) => {
            state.bet0.push(action.payload);
            sessionStorage.setItem('bet', JSON.stringify(action.payload));
        },
        // addBet1: (state,action) => {
        //     state.bet1.push(action.payload);
        //     sessionStorage.setItem('bet', JSON.stringify(action.payload));
        // },
        addBet2: (state,action) => {
            state.bet2 = action.payload;
            sessionStorage.setItem('bet', JSON.stringify(action.payload));
        },
        // setBet: (state, action) => {
        //     state.bet0 = action.payload;
        //     localStorage.setItem('bet', JSON.stringify(action.payload));
        // },
        delBet: (state,action)=>{
            state.bet0 = action.payload;
        },
        clearBet: (state,action)=>{
            state.bet0 = [];
        }
    },
});

export const { setBet,addBet, addBet1,addBet2, delBet, clearBet } = betSlice.actions;

export default betSlice.reducer;