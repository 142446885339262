import React from "react";
import { useState } from "react";
import OtpNum1 from "../components/otpnumber/OtpNum1";
import OtpNum3 from "../components/otpnumber/OtpNum3";

const NumberLotto1 = ({ betType }) => {
  // const [selected, setSelected] = useState(1);

  const [numPattern, setNumPattern] = useState(1);
  // const [selected, setSelected] = useState(false)

  const patternDisplay = () => {
    if (numPattern === 0) {
      return <OtpNum1 newBetType={newBetType} />;
    } else if (numPattern === 1) {
      return <OtpNum3 newBetType={newBetType} />;
    } else {
      return <OtpNum3 newBetType={newBetType} />;
    }
  };

  let newBetType = betType + numPattern;
  console.log(newBetType);

  return (
    <>
      <div className="box-number-LottoPlay">
        <div className="line-bottom-LottoPlay">รูปแบบเลข 3</div>
        <div style={{ height: "20px" }}></div>
        <div className="">
          <div className="flex-1-LottoPlay flex-LottoPlay flex-wrap-LottoPlay">
            <button
              className={
                numPattern === 1
                  ? "btn-select-play-LottoPlay flex-1-LottoPlay left-border-LottoPlay btn-select-play-action-LottoPlay"
                  : "btn-select-play-LottoPlay flex-1-LottoPlay left-border-LottoPlay"
              }
              onClick={() => {
                // setSelected(2)
                // setSelected(false)
                setNumPattern(1);
              }}
            >
              ตรง
            </button>
            <button
              className={
                numPattern === 2
                  ? "btn-select-play-LottoPlay flex-1-LottoPlay  btn-select-play-action-LottoPlay"
                  : "btn-select-play-LottoPlay flex-1-LottoPlay "
              }
              onClick={() => {
                // setSelected(1)
                // setSelected(true)
                setNumPattern(2);
              }}
            >
              โต๊ด
            </button>
            <button
              className={
                numPattern === 0
                  ? "btn-select-play-LottoPlay flex-1-LottoPlay right-border-LottoPlay btn-select-play-action-LottoPlay"
                  : "btn-select-play-LottoPlay flex-1-LottoPlay right-border-LottoPlay"
              }
              onClick={() => {
                // setSelected(0)
                // setSelected(true)
                setNumPattern(0);
              }}
            >
              วิ่ง
            </button>
          </div>
        </div>
        <div style={{ height: "20px" }}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="font-09-LottoPlay">กรอกตัวเลขที่ต้องการแทง</div>
        </div>
        {/* <OtpNum1 /> */}
        {/* <OtpNum2 /> */}
        {/* <OtpNum3 /> */}
        {patternDisplay()}
      </div>
    </>
  );
};

export default NumberLotto1;
