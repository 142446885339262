import React from 'react'

// dd mean 0:sun 1:mon 2:tue 3:wed 4:thu 5:fri 6:sat
function EndMonth1({dd,hh,mm,ss}) {
    const EndWeek = getNextWeekStartDate(dd,hh,mm,ss)
    // console.log(EndWeek)
    // Helper function to get the start date of the next week
    function getNextWeekStartDate(day,hour,min,sec) {
      const today = new Date();
      const nextMonthStartDate = new Date(today.getFullYear(), today.getMonth() , day,hour,min,sec,0);
        if(today >= nextMonthStartDate){
            nextMonthStartDate.setMonth(today.getMonth()+1);
        }
      return nextMonthStartDate;
    }
  return (
    <>
      {EndWeek.toLocaleDateString('th-TH')} <br />
      {EndWeek.toLocaleTimeString('en-US',{ hour: 'numeric', minute: 'numeric', hour12: true })}
    </>
  )
}

export default EndMonth1
