import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   
    myOwner: sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo')).myOwner
    : null,
}

const ownerSlice = createSlice({
    name: 'owner',
    initialState,
    reducers: {
        myOwner: (state, action) =>{
            state.myOwner = action.payload;
        }
    }
})

export const {myOwner} = ownerSlice.actions;

export default ownerSlice.reducer;