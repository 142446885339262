import React, { useState } from "react";
import ppf12 from "../../images/ppf12.png";
import ppf13 from "../../images/ppf13.png";
import ppf14 from "../../images/ppf14.png";
import ppf15 from "../../images/ppf15.png";
import BottomNav from "../../components/BottomNav";
import "./prize.css";
import {

  formatDate,
  getTimeConstant,
} from "../../components/helper/HelperTime";
import { useDispatch, useSelector } from "react-redux";
import images from "../../components/helper/ImageImports";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {  useLastLottoTenMutation } from "../../slices/lottosApiSlice";
import { setLotto } from "../../slices/lottoSlice";

// function getImage(arr,category){
//   return arr.category
// }
// console.log(getImage(images,"fox"))

function Prize() {

  // const { userInfo } = useSelector((state) => state.auth);

  const { ourLotto } = useSelector((state) => state.lotto);

  const ourLotto1 = [...ourLotto];
// console.log(ourLotto1.length)
  // const [lastLotto] = useLastLottoMutation();
  const [lastLottoTen] = useLastLottoTenMutation();
  // console.log(lastLottoTen);
  const dispatch = useDispatch();


  const allLotto = (allLotto) =>
    allLotto.map((item) => (
      <div className="box-prize" key={item._id}>
        <div className="flex-prize">
          <div className="prize-name">
            <img
              src={images[item.category]}
              className="iconprize2"
              alt="karen"
            />
          </div>
          <div className="prize-name1">
            <img
              src={images.ppf_logo}
              className="iconprize2"
              alt="karen"
            />
          </div>
          
          <div className="right-prize">
            <div className="font18 prize-name m-auto plr5">{item.category.toUpperCase()}</div>
            <div className="f14r1">Date: {formatDate(item.lottoDate)}</div>
            <div className="f14r1 bg-green">Time: {getTimeConstant(item.category)}</div>
          </div>
        </div>

        <div className="prize">
          <div className="item-prize"><span className="bg-green1">รางวัลที่ 1 </span><br /><span className="redblack">ပထမဆု</span></div>
          <div className="item-prize"><span className="bg-green1">รางวัลที่ 2 </span><br /><span className="redblack">ဒုတိယဆု</span></div>
          <div className="item-prize"><span className="bg-green1">รางวัลที่ 3 </span><br /><span className="redblack">တတိယဆု</span></div>
        </div>
        <div className="prize">
          <div className="item-prize-3">{item.prize.firstPrize}</div>
          <div className="item-prize-3">{item.prize.secondPrize}</div>
          <div className="item-prize-3">{item.prize.thirdPrize}</div>
        </div>
        <div className="prize border-bottom">
          <div className="item-prize item-prize-left1">รางวัล <span className="redblack">ဆုလာဘ်</span></div>
          <div className="item-prize item-prize-right1">ผลรางวัล <span className="redblack">ရလဒ်</span></div>
        </div>
        
        <div className="prize">
          <div className="item-prize item-prize-left1 bg-green">3 ตัว บน</div>
          <div className="item-prize item-prize-right">
            {item.prize.threeTopOne}
          </div>
        </div>
        <div className="prize1">
          <div className="item-prize item-prize-left redblack">အပော္သုံးလုံး</div>
        </div>
        
        <div className="prize">
          <div className="item-prize item-prize-left1 bg-green">3 ตัว บน วิ่ง</div>
          <div className="item-prize item-prize-right">
            {item.prize.threeTopZero.join(" , ")}
          </div>
        </div>
        <div className="prize1">
          <div className="item-prize item-prize-left redblack ">အပော္သံုးလုံး‌ေျပး</div>
        </div>
        
        <div className="prize">
          <div className="item-prize item-prize-left1 bg-green">3 ตัว โต๊ด</div>
          <div className="item-prize item-prize-right">
            {item.prize.threeTopTwo.join(" , ")}
          </div>
        </div>
        <div className="prize1">
          <div className="item-prize item-prize-left redblack ">သုံးလုံးပတ္လည္</div>
        </div>
        
        <div className="prize">
          <div className="item-prize item-prize-left1 bg-green">2 ตัวบน</div>
          <div className="item-prize item-prize-right">
            {item.prize.twoTopOne}
          </div>
        </div>
        <div className="prize1">
          <div className="item-prize item-prize-left redblack ">အ‌ေပာ္နွစ္လုံး</div>
        </div>
        
        <div className="prize">
          <div className="item-prize item-prize-left1 bg-green">2 ตัว ล่าง</div>
          <div className="item-prize item-prize-right">
            {item.prize.twoBottomOne}
          </div>
        </div>
        <div className="prize1">
          <div className="item-prize item-prize-left redblack ">အောက္နွစ္လုံး</div>
        </div>
        
        <div className="prize">
          <div className="item-prize item-prize-left1 bg-green">2 ตัว บน วิ่ง</div>
          <div className="item-prize item-prize-right">
            {item.prize.twoBottomZero.join(" , ")}
          </div>
        </div>
        <div className="prize1">
          <div className="item-prize item-prize-left redblack ">အေပာ္နွစ္လုံး‌ေျပး</div>
        </div>
      </div>
    ));

  // let today = getFormattedDate();
  // console.log(today);
  // const today = new Date();

  const [selectedDate, setSelectedDate] = useState(new Date());
  // const url = window.location.href;
  // console.log(url);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const submitHandlePrizeClick = async(e) => {
    e.preventDefault();
    try{
      const res = await lastLottoTen({
        findDate: selectedDate
      }).unwrap();
      console.log(res)
      dispatch(setLotto(res.lastLotto))
      // console.log(selectedDate);
    }catch(err){
      console.log(err);
    }
    
  }

  // useEffect(()=>{
  //   console.log(selectedDate)
  // },[selectedDate])

  return (
    <>
      <div className="layout-prize">
        <div className="relative">
          <div className="container-prize">
            <button type="button" className="btn-prize">
              ผลรางวัล
            </button>
            <div className="searchDate ">
              <p className="f18r1">วันที่</p>
              <DatePicker
               
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                className="datepicker"
              />

              <button type="button" className="btn-prize1" onClick={submitHandlePrizeClick}>
                ค้นหา
              </button>
            </div>
            <button type="button" className="btn-prize">
              หมวดหมู่หวย
            </button>
            <div className="category-prize">
              <button type="button" className="btn-category-lotto">
                <img src={ppf12} className="iconprize" alt="" />
              </button>
              <button type="button" className="btn-category-lotto">
                <img src={ppf13} className="iconprize" alt="" />
              </button>
              <button type="button" className="btn-category-lotto">
                <img src={ppf14} className="iconprize" alt="" />
              </button>
              <button type="button" className="btn-category-lotto">
                <img src={ppf15} className="iconprize1" alt="" />
              </button>
            </div>
          </div>
          <br />
          {ourLotto1.length !== 0 ? (
            allLotto(ourLotto1)
          ) : (
            <div className="text-center">
              <h1>วันนี้ ไม่มี หวยออก</h1>
            </div>
          )}
        </div>
      </div>
      <BottomNav />
    </>
  );
}

export default Prize;
