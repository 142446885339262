import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: sessionStorage.getItem('userInfo')
    ? JSON.parse(sessionStorage.getItem('userInfo'))
    : null,
};

// Function to clear a cookie by setting its expiration date to a past date
// const clearCookie = (name) => {
//   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
// };

// Example: clear a cookie named "jwtToken"


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload;
      sessionStorage.setItem('userInfo', JSON.stringify(action.payload));
    },
    logout: (state, action) => {
      state.userInfo = null;
      state.balance0 = null;
      state.bet = null;
      state.owner = null;
      state.lotto = null;

     
      // clearCookie('jwt');
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('balance');
      sessionStorage.removeItem('newBetArray');
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;
