import React from "react";
import { useState } from "react";

import images from "../../components/helper/ImageImports";

// import { Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
// import ContainerLotto1 from "../../components/ContainerLotto1";
// import ContainerLotto2 from "../../components/ContainerLotto2";
import BottomNav from "../../components/BottomNav";
// import CountdownResetWeekly from "../../components/EndWeekTime1";
// import EndWeek from "../../components/EndWeek";

import NumberLotto from "../../components/NumberLotto";
import NumberLotto1 from "../../components/NumberLotto1";
import { useParams } from "react-router-dom";
import EndWeekTime1 from "../../components/EndWeekTime1";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../../components/SideBar";
import AddBetRates from "../../components/AddBetRates";
import { clearBet } from "../../slices/betSlice";
import NumberLotto11 from "../../components/NumberLotto11";
// import EndMonthTime from '../../components/EndMonthTime'
// import EndMonth from '../../components/EndMonth'
import EndDay from "../../components/EndDay";
import EndDayTime from "../../components/EndDayTime"
import EndMonth1 from "../../components/EndMonth1";
import EndMonthTime1 from "../../components/EndMonthTime1";
import { Badge } from "react-bootstrap";
import EndWeek1 from "../../components/EndWeek1";

// Helper Function

// function for remove duplicate in array
// function removeDuplicates(arr) {
//   return [...new Set(arr)];
// }

function PlayLotto() {
  const { categoryParams } = useParams();
  const dispatch = useDispatch();
  const { bet0 } = useSelector((state) => state.bet);
  const bet00 = [...bet0];
  const { myOwner } = useSelector((state) => state.owner);



  let myOwnerL = myOwner?(myOwner.length): 0;
  // console.log(myOwnerL);


// Function to check if category "dog" is included and return ownerType
function findOwnerTypeForCategory(categoryToFind) {
  const foundItem = (myOwnerL > 0) ? myOwner.find(item => item.category === categoryToFind): false;

  if (foundItem) {
      return foundItem.ownerType.toUpperCase();
  } 
}
// Function to check if category "dog" is included and return ownerType
function findOwnerObtainForCategory(categoryToFind) {
  const foundItem = (myOwnerL >0) ? myOwner.find(item => item.category === categoryToFind) : false;

  if (foundItem) {
      return foundItem.obtain;
  } else {
      return "Category not found";
  }
}

console.log(findOwnerObtainForCategory(categoryParams))
  // const tabAdmin = admin => {
  //   if()
  // }
  const countBet = () => {
    if (bet00) {
      return bet00.length;
    }
  };

  const clearAllBet = () => {
    dispatch(clearBet());
  };

  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  

  const selectedImage = images[categoryParams];
  const upCategory = categoryParams =>{
    if(categoryParams === "goldwing"){
      return "GOLD WING"
    } else if(categoryParams === "huaystar"){
      return "HUAY STAR"
    } else if(categoryParams === "huaycrown"){
      return "HUAY CROWN"
    } else if(categoryParams === "huaykaren"){
      return "HUAY KAREN"
    } else {
      return categoryParams.toUpperCase()
    }
  }

  console.log(upCategory(categoryParams))

  const buttons = ["2 ตัวบน", "2 ตัวล่าง", "3 ตัวบน"];
  const [selected, setSelected] = useState(buttons[0]);
  // console.log(selected)
  let betType = "2 ตัวบน";
  if (selected === "2 ตัวบน") {
    betType = "2t";
  } else if (selected === "2 ตัวล่าง") {
    betType = "2b";
  } else if (selected === "3 ตัวบน") {
    betType = "3t";
  }
  console.log(betType);

  const topNumDisplay = () => {
    if (selected === buttons[0]) {
      return <NumberLotto11 betType={betType} />;
    } else if (selected === buttons[1]) {
      return <NumberLotto betType={betType} />;
    } else {
      return <NumberLotto1 betType={betType} />;
    }
  };

  const EndWeekCategory = () => {
    // let category = categoryParams;
    // console.log(category);
    // typeof category;
    if (categoryParams === "cat") {
      return <EndWeek1 dd={1} hh={9} mm={0} ss={0} />;
    } else if (categoryParams === "dog") {
      return <EndWeek1 dd={1} hh={10} mm={0} ss={0} />;
    } else if (categoryParams === "cheetah") {
      return <EndWeek1 dd={1} hh={11} mm={0} ss={0} />;
    } else if (categoryParams === "penguin") {
      return <EndWeek1 dd={1} hh={12} mm={0} ss={0} />;
    } else if (categoryParams === "elephant") {
      return <EndWeek1 dd={1} hh={13} mm={0} ss={0} />;
    } else if (categoryParams === "bee") {
      return <EndWeek1 dd={2} hh={9} mm={0} ss={0} />;
    } else if (categoryParams === "fish") {
      return <EndWeek1 dd={2} hh={10} mm={0} ss={0} />;
    } else if (categoryParams === "monkey") {
      return <EndWeek1 dd={2} hh={11} mm={0} ss={0} />;
    } else if (categoryParams === "panda") {
      return <EndWeek1 dd={2} hh={12} mm={0} ss={0} />;
    } else if (categoryParams === "turtle") {
      return <EndWeek1 dd={2} hh={13} mm={0} ss={0} />;
    } else if (categoryParams === "bull") {
      return <EndWeek1 dd={3} hh={9} mm={0} ss={0} />;
    } else if (categoryParams === "cow") {
      return <EndWeek1 dd={3} hh={10} mm={0} ss={0} />;
    } else if (categoryParams === "fox") {
      return <EndWeek1 dd={3} hh={11} mm={0} ss={0} />;
    } else if (categoryParams === "hippopo") {
      return <EndWeek1 dd={3} hh={12} mm={0} ss={0} />;
    } else if (categoryParams === "ladybug") {
      return <EndWeek1 dd={3} hh={13} mm={0} ss={0} />;
    } else if (categoryParams === "bear") {
      return <EndWeek1 dd={4} hh={9} mm={0} ss={0} />;
    } else if (categoryParams === "dragon") {
      return <EndWeek1 dd={4} hh={10} mm={0} ss={0} />;
    } else if (categoryParams === "lion") {
      return <EndWeek1 dd={4} hh={11} mm={0} ss={0} />;
    } else if (categoryParams === "rhinoceros") {
      return <EndWeek1 dd={4} hh={12} mm={0} ss={0} />;
    } else if (categoryParams === "snake") {
      return <EndWeek1 dd={4} hh={13} mm={0} ss={0} />;
    } else if (categoryParams === "goldwing") {
      return <EndMonth1 dd={1} hh={15} mm={0} ss={0}  />;
    } else if (categoryParams === "huaystar") {
      return <EndMonth1 dd={16} hh={15} mm={0} ss={0}  />;
    } else if (categoryParams === "huaycrown") {
      return <EndMonth1 dd={28} hh={15} mm={0} ss={0}  />;
    } else if (categoryParams === "huaykaren") {
      return <EndDay hh={16} mm={0} ss={0}  />;
    } else {
      console.log("Noo");
    }
  };

  const EndTimeCategory = () => {
    if (categoryParams === "cat") {
      return <EndWeekTime1 dd={1} hh={9} mm={0} ss={0} />;
    } else if (categoryParams === "dog") {
      return <EndWeekTime1 dd={1} hh={10} mm={0} ss={0} />;
    } else if (categoryParams === "cheetah") {
      return <EndWeekTime1 dd={1} hh={11} mm={0} ss={0} />;
    } else if (categoryParams === "penguin") {
      return <EndWeekTime1 dd={1} hh={12} mm={0} ss={0} />;
    } else if (categoryParams === "elephant") {
      return <EndWeekTime1 dd={1} hh={13} mm={0} ss={0} />;
    } else if (categoryParams === "bee") {
      return <EndWeekTime1 dd={2} hh={9} mm={0} ss={0} />;
    } else if (categoryParams === "fish") {
      return <EndWeekTime1 dd={2} hh={10} mm={0} ss={0} />;
    } else if (categoryParams === "monkey") {
      return <EndWeekTime1 dd={2} hh={11} mm={0} ss={0} />;
    } else if (categoryParams === "panda") {
      return <EndWeekTime1 dd={2} hh={12} mm={0} ss={0} />;
    } else if (categoryParams === "turtle") {
      return <EndWeekTime1 dd={2} hh={13} mm={0} ss={0} />;
    } else if (categoryParams === "bull") {
      return <EndWeekTime1 dd={3} hh={9} mm={0} ss={0} />;
    } else if (categoryParams === "cow") {
      return <EndWeekTime1 dd={3} hh={10} mm={0} ss={0} />;
    } else if (categoryParams === "fox") {
      return <EndWeekTime1 dd={3} hh={11} mm={0} ss={0} />;
    } else if (categoryParams === "hippopo") {
      return <EndWeekTime1 dd={3} hh={12} mm={0} ss={0} />;
    } else if (categoryParams === "ladybug") {
      return <EndWeekTime1 dd={3} hh={13} mm={0} ss={0} />;
    } else if (categoryParams === "bear") {
      return <EndWeekTime1 dd={4} hh={9} mm={0} ss={0} />;
    } else if (categoryParams === "dragon") {
      return <EndWeekTime1 dd={4} hh={10} mm={0} ss={0} />;
    } else if (categoryParams === "lion") {
      return <EndWeekTime1 dd={4} hh={11} mm={0} ss={0} />;
    } else if (categoryParams === "rhinoceros") {
      return <EndWeekTime1 dd={4} hh={12} mm={0} ss={0} />;
    } else if (categoryParams === "snake") {
      return <EndWeekTime1 dd={4} hh={13} mm={0} ss={0} />;
    } else if (categoryParams === "goldwing") {
      return <EndMonthTime1 dd={1} hh={15} mm={0} ss={0} />;
    } else if (categoryParams === "huaystar") {
      return <EndMonthTime1 dd={16} hh={15} mm={0} ss={0} />;
    } else if (categoryParams === "huaycrown") {
      return <EndMonthTime1 dd={28} hh={15} mm={0} ss={0} />;
    } else if (categoryParams === "huaykaren") {
      return <EndDayTime hh={16} mm={0} ss={0} />;
    } else {
      console.log("Noo");
    }
  };
  // EndTimeCategory()

  return (
    <>
      <div className="layout-lottoplay">
        <div className="relative-lottoplay">
          <div className="container-lottoplay">
            <div className="box-top-lottoplay">
              <div className="grid-container">
                <div className="grid-item item11">
                  <img alt="" src={selectedImage} className="charity1" />
                  <b>
                    <p>{upCategory(categoryParams)}</p>
                  </b>
                  {myOwnerL !== 0 ?
                  <Link to={`/report/${categoryParams}`}>
                   <p className="font77"  >{findOwnerTypeForCategory(categoryParams)}</p> </Link>
                   : 
                   ""
                   }
                   
                </div>
                <div className="grid-item item2">
                  <h6>
                    {" "}
                    <b>{`ထီပေါက်ချိန်ကို သတ်မှတ်ပါ။`}</b>
                  </h6>
                </div>
                <div className="grid-item item3">
                  <div className="font12 ">
                    <b>{`Daily Period`}</b>
                    <p>{`งวดประจำวันที่`}</p>
                    <div className="datetimeCharity">
                      <b>{EndWeekCategory()}</b>
                    </div>
                  </div>
                </div>
                <div className="grid-item item4">
                  <div className="font12">
                    <b>{`Remaining Time`}</b> <br />
                    {`เหลือเวลาทายผล`} <br />
                    <h5>{EndTimeCategory()}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-lottolist-lottoplay" style={{ height: "60px" }}>
              <div className="list-lottoplay">
                รายการแทง {countBet()} รายการ
              </div>
            </div>
            <div className="box-main-LottoPlay">
              {bet0.length !== 0 ? <SideBar /> : <></>}

              <div className="widthLottoPlayArea">
                <div className="box-play-LottoPlay">
                  <div className="flex-LottoPlay flex-wrap-LottoPlay ">
                    {buttons.map((button, index) => (
                      <button
                        key={index}
                        className={
                          selected === button
                            ? "btn-select-LottoPlay active-LottoPlay"
                            : "btn-select-LottoPlay"
                        }
                        onClick={() => setSelected(button)}
                      >
                        {button}
                      </button>
                    ))}
                  </div>
                  {topNumDisplay()}
                  <div className="box-bottom-lottoplay">
                    <div className="playBtn">
                      <button
                        className="btn-nonesentpost-LottoPlay"
                        onClick={() => clearAllBet()}
                      >
                        ล้างข้อมูล
                      </button>
                      <button
                        className="btn-sentpost-LottoPlay"
                        onClick={() => setShow(true)}
                      >
                        ใส่ราคา
                      </button>
                      <Modal
                        size={"lg"}
                        show={show}
                        onHide={() => setShow(false)}
                        aria-labelledby="example-custom-modal-styling-title"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="example-custom-modal-styling-title">
                          <Badge bg="success"> รายการแทง {countBet()} รายการ</Badge>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <AddBetRates closeModal={closeModal} />
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}

export default PlayLotto;

// const arrAnimal = {
//   cat: "x95",
//   dog: "x4",
// };
