import React, { useEffect, useState } from "react";
import { Form, Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUserMutation } from "../../slices/usersApiSlice";
import { setCredentials } from "../../slices/authSlice";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import bbl from "../../images/bbl.png";
import kbank from "../../images/kbank.png";
import scb from "../../images/scb.png";
import ktb from "../../images/ktb.png";
import ttb from "../../images/ttb.png";
import gsb from "../../images/gsb.jpg";
import citi from "../../images/citi.jpg";
import ghb from "../../images/ghb.png";
import bay from "../../images/bay.png";

const EditProfile = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const [name, setName] = useState(userInfo.name);
  const [phone, setPhone] = useState(userInfo.phone)
  const [balance, setBalance ] = useState(userInfo.balance)
  const [suggester, setSuggester] = useState(userInfo.suggester);
  const [bankName, setBankName] = useState(userInfo.bank.bankName || "");
  const [bankUser, setBankUser] = useState(userInfo.bank.bankUser || "");
  const [bankAccount, setBankAccount] = useState(
    userInfo.bank.bankAccount || ""
  );
  // const [selectedOption, setSelectedOption] = useState(
  //   userInfo.bank.bankName || ""
  // );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateUser] = useUpdateUserMutation();

  const options = [
    { value: "KBANK", label: "ธนาคารกสิกรไทย KBANK", image: kbank },
    { value: "KTB", label: "ธนาคารกรุงไทย KTB", image: ktb },
    { value: "SCB", label:"ธนาคารไทยพาณิชย์ SCB", image: scb },
    { value: "BBL", label:"ธนาคารกรุงเทพ BBL", image: bbl },
    { value: "GSB", label:"ธนาคารออมสิน GSB", image: gsb },
    { value: "CITI", label:"ธนาคารซิตี้แบงค์ CITI", image: citi },
    { value: "GHB", label:"ธนาคารอาคารสงเคราะห์ GHB", image: ghb },
    { value: "BAY", label:"ธนาคารกรุงศรีอยุธยา BAY", image: bay },
    { value: "TTB", label:"ธนาคารทหารไทยธนชาติ TTB", image: ttb },
  ];

  const formatOptionLabel = ({ label, image }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={image}
        alt={label}
        style={{ width: "30px", height: "30px", marginRight: "10px" }}
      />
      <span>{label}</span>
    </div>
  );


  // console.log(bankName)

  // useEffect(()=>{
  //   setName(userInfo.name);
  //   setSuggester(userInfo.suggester);
  // },[userInfo.name, userInfo.suggester])

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await updateUser({
        _id: userInfo._id,
        name,
        suggester,
        bankName,
        bankUser,
        bankAccount,
      }).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate("/profile");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="main">
      <div className="page">
        <div className="layout-profile">
          <div className="relative-profile">
            <div className="container-profile">
              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="suggester">
                  <Form.Label>Suggester</Form.Label>
                  <Form.Control
                    type="suggester"
                    placeholder="suggester"
                    value={suggester}
                    onChange={(e) => setSuggester(e.target.value)}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="bankName">
                  <Form.Label>Bank</Form.Label>

                  <Select
                    aria-label={bankName}
                    type="bankName"
                    onChange={(choose) => setBankName(choose.value)}
                    defaultValue= {options.filter(option => option.value === bankName)}
                    options={options}
                    formatOptionLabel={formatOptionLabel}
                  />
                  {/* <Form.Select
                    aria-label={bankName}
                    type="bankName"
                    onChange={(e) => setBankName(e.target.value)}
                    defaultValue={bankName}
                    // options={options}
                    // formatOptionLabel={formatOptionLabel}
                  >
               
                    <option value="">Select Bank Name</option>
                    <option value="SCB">ธนาคารไทยพาณิชย์ SCB</option>
                    <option value="BBL">ธนาคารกรุงเทพ BBL</option>
                    <option value="KTB">ธนาคารกรุงไทย KTB</option>
                    <option value="KBANK">ธนาคารกสิกรไทย KBANK</option>
                    <option value="GSB">ธนาคารออมสิน GSB</option>
                    <option value="CITI">ธนาคารซิตี้แบงค์ CITI</option>
                    <option value="GHB">ธนาคารอาคารสงเคราะห์ GHB</option>
                    <option value="BAY">ธนาคารกรุงศรีอยุธยา BAY</option>
                    <option value="TTB">ธนาคารทหารไทยธนชาติ TTB</option>
                  </Form.Select> */}


                </Form.Group>
                <Form.Group className="mb-3" controlId="bankUser">
                  <Form.Control
                    type="bankUser"
                    placeholder="Bank UserName"
                    value={bankUser}
                    onChange={(e) => setBankUser(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="bankAccount">
                  <Form.Control
                    type="bankAccount"
                    placeholder="Bank User Account"
                    value={bankAccount}
                    onChange={(e) => setBankAccount(e.target.value)}
                  />
                </Form.Group>
                <Button type="submit" variant="primary" className="mt-3">
                  Update
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
