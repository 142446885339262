// import React, { useState } from "react";
import { Badge } from "react-bootstrap";
// import bank from "../../images/bank.png";
import bbl from "../../images/bbl.png";
import kbank from "../../images/kbank.png";
import scb from "../../images/scb.png";
import ktb from "../../images/ktb.png";
import ttb from "../../images/ttb.png";
import gsb from "../../images/gsb.jpg";
import citi from "../../images/citi.jpg";
import ghb from "../../images/ghb.png";
import bay from "../../images/bay.png";

import BottomNav from "../../components/BottomNav";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faIdCard, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Profile() {
  const { userInfo } = useSelector((state) => state.auth);

  // const [textToCopy, setTextToCopy] = useState('Hello, world!');

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`https://www.ppf4app.com/register?suggester=${userInfo._id}`)
      .then(() => alert('Text copied to clipboard'))
      .catch((error) => console.error('Unable to copy text', error));
  };


  const bankImg = userInfo.bank.bankName;
  const bankPic = () => {
    if (bankImg === "KBANK") {
      return `${kbank}`;
    }
    if (bankImg === "BBL") {
      return `${bbl}`;
    }
    if (bankImg === "SCB") {
      return `${scb}`;
    }
    if (bankImg === "KTB") {
      return `${ktb}`;
    }
    if (bankImg === "GSB") {
      return `${gsb}`;
    }
    if (bankImg === "CITI") {
      return `${citi}`;
    }
    if (bankImg === "GHB") {
      return `${ghb}`;
    }
    if (bankImg === "BAY") {
      return `${bay}`;
    }
    if (bankImg === "TTB") {
      return `${ttb}`;
    }
  };
  const bankFullName = () => {
    // console.log(bankImg);
    if (bankImg === "KBANK") {
      return "กสิกร KBANK";
    }
    if (bankImg === "BBL") {
      return "กรุงเทพ BBL";
    }
    if (bankImg === "SCB") {
      return "ไทยพาณิชย์ SCB";
    }
    if (bankImg === "KTB") {
      return "กรุงไทย KTB";
    }
    if (bankImg === "GSB") {
      return "ออมสิน GSB";
    }
    if (bankImg === "CITI") {
      return "ซิตี้แบงค์ CITI";
    }
    if (bankImg === "GHB") {
      return "อาคารสงเคราะห์ GHB";
    }
    if (bankImg === "BAY") {
      return "กรุงศรีอยุธยา BAY";
    }
    if (bankImg === "TTB") {
      return "ทหารไทยธนชาติ TTB";
    }
  };

  const navigate = useNavigate();

  const handleFix = () => navigate("/editprofile");
  return (
    <>

        <div className="layout-profile">
          <div className="relative-profile">
            <div className="container-profile">
              {/* <div style={{position: 'relative'}}>ข้อมูลโปรไฟล์</div> */}
              <h6><span className="title-history-left">ข้อมูลโปรไฟล์</span> </h6>
              <div className="box-profile">
                <div className="circle-icon-profile">
                  <div style={{ fontSize: "32px" }}>
                    <FontAwesomeIcon icon={faIdCard} />
                  </div>
                </div>
                <div className="text-profile">
                  <div className="ID-profile">
                    NAME : {userInfo ? userInfo.name : ""}
                  </div>
                  <div className="box-font08-profile">
                    <div>{userInfo ? userInfo.phone : ""} </div>
                    <div>{userInfo ? userInfo.lastBalance : "0"} ฿</div>
                  </div>
                </div>
              </div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: "13px",
                  color: "rgb(201,3,3)",
                }}
              >
                {" "}
                <span className="title-history-left">เปลี่ยนรหัสผ่าน ?</span>
                
              </p>
            </div>
            <div style={{ height: "200px" }}></div>
            <div className="container-contact-profile">
              <div className="top-contact-profile">
                <div className="title-contact-profile">
                  <Badge pill bg="success" className="font14w">
                    ลิงค์แนะนำ
                  </Badge>{" "}
                  <div className="fix-right" onClick={handleCopyClick}>
                    <FontAwesomeIcon
                      icon={faClone}
                      style={{ marginRight: "5px" }}
                    />
                    copy link
                  </div>
                </div>
              </div>
              <div className="bottom-contact-profile">
                <div>{userInfo ? `https://www.ppf4app.com/register?suggester=${userInfo._id}` : ""}</div>
              </div>
            </div>
            <div className="container-contact-profile">
              <div className="top-contact-profile">
                <div className="title-contact-profile">
                  <Badge pill bg="success" className="font14w">
                    ผลประโยชน์จากการแนะนำ
                  </Badge>{" "}
                </div>
              </div>
              <div className="bottom-contact-profile">
                <div>
                  รับคอมมิชชั่นจากทุกยอดการซื้อ รับคอมมิชชั่นจากการยอดการซื้อ
                  หมายถึง
                  การรับรายได้จากทุกยอดการซื้อของสมาชิกที่คุณแนะนำมาเท่านั้น
                  ตัวอย่างเช่น เมื่อสมาชิกคุณซื้อหวย 1000 บาท
                  คุณจะได้รับคอมมิชชั่น ตามเปอร์เซ็นต์ที่กำหนด
                  โดยระบบจะทำการคำนวนให้ทันที ที่มีการซื้อ
                </div>
              </div>
            </div>
            <div className="container-contact-profile">
              <div className="top-contact-profile">
                <div className="title-contact-profile">
                  <Badge pill bg="success" className="font14w">
                    ข้อมูลการติดต่อ
                  </Badge>{" "}
                  <div className="fix-right" onClick={handleFix}>
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{ marginRight: "5px" }}
                    />
                    แก้ไข
                  </div>
                </div>
              </div>
              <div className="bottom-contact-profile">
                <Badge pill bg="warning"  className="font14">เบอร์โทรศัพท์</Badge> 
                <div className="ml5">{userInfo.phone}</div>
                <br />
                <Badge pill bg="warning"  className="font14">ชื่อ - นามสกุล</Badge> 
                
                <div className="ml5">{userInfo.name}</div>
              </div>
            </div>
            <div className="container-contact-profile">
              <div className="top-contact-profile">
                <div className="title-contact-profile">
                  <Badge pill bg="success" className="font14w">
                    ข้อมูลธนาคาร
                  </Badge>{" "}
                  <div className="fix-right" onClick={handleFix}>
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{ marginRight: "5px" }}
                    />
                    แก้ไข
                  </div>
                </div>
              </div>
              {userInfo.bank.bankName ? (
                <>
                  <div className="acc-bank-profile">
                    <img
                      src={bankPic()}
                      className="logo-bank-profile"
                      alt="bank"
                    />
                    <div className="text-bank-profile">
                      <div>ธนาคาร : {bankFullName()}</div>
                      <div className="ID-Withdraw">
                        เลขที่ :{userInfo.bank.bankAccount}
                      </div>
                      <div className="box-font-withdraw">
                        <div>ชื่อบัญชี : {userInfo.bank.bankUser}</div>
                        {/* <div>
                      สถานะ :<span style={{ color: "green" }}>กำลังใช้งาน</span>{" "}
                    </div> */}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <br />
                  <h4 className="red">Please update Bank Information </h4>
                  <br />
                </>
              )}
            </div>
          </div>
        </div>
        <BottomNav />

    </>
  );
}

export default Profile;
