import logomm68 from "../../images/logomm68.jpg";
import thankyou from "../../images/thank.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPhone, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useLoginMutation } from "../../slices/usersApiSlice";
import { setCredentials } from "../../slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "../../components/Loader";
import { setBalance } from "../../slices/balanceSlice";
import { setBet } from "../../slices/betSlice";
import { myOwner } from "../../slices/ownerSlice";
import { setLotto } from "../../slices/lottoSlice";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  // const [balance] = useTotalMutation();

  const { userInfo } = useSelector((state) => state.auth);
  // console.log(userInfo.lastBalance)




  useEffect(() => {
    if (userInfo) {
      // console.log(typeof(userInfo.lastBalance))
      // console.log(typeof(userInfo.token))
      console.log(userInfo.lastBalance)
      
      userInfo.lastBalance >= 0
      // ? console.log(userInfo.lastBalance)
        ? dispatch(setBalance(userInfo.lastBalance))
        : console.log("Not Have balance");

      // userInfo.token
      //   ? dispatch(setToken(userInfo.token))
      //   : console.log("Not Have balance");

      userInfo.myBet
        ? dispatch(setBet(userInfo.myBet))
        : console.log("Not Have Bet");

      userInfo.myOwner
        ? dispatch(myOwner(userInfo.myOwner))
        : console.log("Not Have Owner");

      userInfo.lotto
        ? dispatch(setLotto(userInfo.lotto))
        : console.log("Not have lotto");

      // if(userInfo.balance){
      //   console.log("hava balance")
      // }else{
      //   console.log("Not hava balance")
      // }
      navigate("/main");
    }
  }, [navigate, userInfo, dispatch]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ phone, password }).unwrap();

      dispatch(setCredentials({ ...res }));

      navigate("/main");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="layout-Login">
      <div className="relative-login">
        <div className="container-login">
          <div className="top">
            <div className="flex-login flex-center-y-loin">
              <img alt="" className="logo-login" src={logomm68} />
            </div>
            <div className="btn-register">
              {/* <Link to="/register">
                <div className="green-login">
                  <Badge pill bg="success" className="green-login1">
                    สมัครสมาชิก
                  </Badge>{" "}
                  <br /> စာရင်းသွင်းပါ။
                </div>
              </Link> */}
            </div>
          </div>
          <br />

          <h3 className="logo-text-login">Login</h3>

          <form onSubmit={submitHandler}>
            <div className="box-login">
              <FontAwesomeIcon icon={faPhone} className="icon-login" />
              <input
                type="tel"
                className="input-login"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="box-login">
              <FontAwesomeIcon icon={faUnlock} className="icon-login" />
              <input
                type="password"
                className="input-login"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {/* { isLoading && <Loader />} */}
            <div className="box-login">
              <button type="submit" className="btn-login">
                เข้าสู่ระบบ / လော့ဂ်အင်
              </button>
            </div>
          </form>
          {isLoading && <Loader />}
          <div className="flex-login">
            <Link to="/forgetpassword" className="text-dec">
              <div className="green-login">
                <Badge pill bg="success" className="green-login1">
                  ลืมรหัสผ่าน
                </Badge>{" "}
                <br />
                စကားဝှက်မေ့နေပါသလား
              </div>
            </Link>
            <div className="flex1-login"></div>
            <a href="https://line.me/ti/p/%40419gtumj" className="text-dec">
              <div className="green-login">
                <Badge pill bg="success" className="green-login1">
                  ติดต่อเรา
                </Badge>{" "}
                <br /> ဆက်သွယ်ပါ။
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="relative-login1">
        <div className="container-login">
          <div className="login-thank">
            <img src={thankyou} alt="" className="login-thankyou" />
          </div>
          <div className="login-thank1">
            <p>
              ပရောဂျက်ကို လှူဒါန်းပေးကြသူအားလုံးကို ကျေးဇူးအထူးတင်ရှိပါတယ်။ 
              ဒီပရဟိတ လှုပ်ရှားမှုကို ပံ့ပိုးကူညီဖို ငွေကြေးလူမျိုးစုများအား
              အထူးအခွင့်အရေးများပေးခြင်းဖြင့် လူတိုင်းကို ဒီအခွင့်အရေးအတွက်
              လောင်းကစားပျော်ပွဲဝင်ဖို အလှူငွေ လှူဒါန်းခဲ့ကြတဲ့ သူတွေကို
              ကျေးဇူးတင်ပါတယ်။
            </p>
            <p>
              ทางโครงการฯขอขอบคุณทุกท่านที่ได้บริจาคเงินในการสนับสนุนกิจกรรมกุศลนี้เพื่อช่วยเหลือกลุ่มชาติพันธุ์
              โดยขอมอบสิทธิพิเศษให้ทุกท่านที่ได้บริจาคเงินได้ร่วมสนุกเสี่ยงโชคเพื่อเป็นการขอบคุณ
              มา ณ โอกาสนี้
            </p>
            <p>
              The project would like to thank everyone who has donated money. in
              supporting this charitable activity to help ethnic group  We would
              like to offer special privileges to everyone.Those who have
              donated money to join in the fun of gambling as a thank you for
              this opportunity.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
