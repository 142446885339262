import React, { useEffect } from 'react'
import BottomNav from '../../components/BottomNav'
import { useDetailsMutation } from '../../slices/balancesApiSlice'
import { useDispatch, useSelector } from 'react-redux';
import { setBalance2 } from '../../slices/balanceSlice';
import { formattedDate } from '../../components/helper/HelperTime';
import { getActivityTranslation, getAmountStyle } from '../../components/helper/HelperBalance';
import { Loader } from '../../components/Loader';



function History() {

    const {balance2} = useSelector((state)=> state.balance0);
    // const detail1 = [...balance2];
    // console.log(detail1);
    let details1 = balance2 !== null ? (balance2.balanceDetails) : null;
    // let details2 = details1 !== null ? (details1.reverse()) : null;


    const balanceHistory = ( details1 ) =>

    details1
    // Create a shallow copy of the array to avoid mutating the original
    .slice() 
    // Reverse the order 
    .reverse()
    .map((item) => {
      const {assign, color , bgColor} = getAmountStyle(item.activity);
  
      return (
        <div className="boxbox-history" key={item._id}>
          <div className="inbox-history">
            <div className="inbox-detail-history">
              <div className="flex-1-history" >
                <span className={`title-left ${bgColor}`}>
                {getActivityTranslation(item.activity)}
                </span>
                </div>
              <div className="right-history">
                <div className={`${color}`}>
                  {`${assign}`} {item.amount} ฿
                </div>
                <div className="flex-history">
                  <div className="font-06-history">
                    {formattedDate(item.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    

    const [details, {isLoading}] = useDetailsMutation();
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async()=>{
            try{
                const res = await details().unwrap();
                // console.log(res);
                dispatch(setBalance2({...res}))

            }catch(err){
                console.log(err);
            }
        }
        fetchData();
    },[details,dispatch])
  return (
<>
            <div className="layout-history">
                <div className="relative">
                    <div className="container-history">
                        <div className="head-history">
                            <div className="flex-1-history title-history">
                               <div className="font-title-history">
                               <span className='title-history-left'>รายการเดินบัญชี</span>  အကောင့်ရှင်းတမ်း


                               </div>
                            </div>
                        </div>
                    </div>
                    {isLoading && <Loader />}
                    <div className="box-history">
                        {balance2 !== null ? (balanceHistory(details1)):(
                            <div>
                            <h1>Don't Have History Yet</h1>
                          </div>
                        )}              
                        
                    </div>
                </div>
            </div>

      <BottomNav />
    </>
  )
}

export default History
