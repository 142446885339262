import React from "react";
import { Link } from "react-router-dom";
import images from "../../components/helper/ImageImports";
import BottomNav from "../../components/BottomNav";
import { useSelector } from "react-redux";
import {
  calculateTotalBetAmount,
  sumBetAmount,
} from "../../components/helper/HelperBet";

const formattedDate = (isoDate) => {
  const date = new Date(isoDate);
  // Define the desired time format
  const options = {
    hour12: false, // Use 24-hour format
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return date.toLocaleString("en-US", options);
};

function OrderList() {
  const { bet2 } = useSelector((state) => state.bet);
  const bet11 = [...bet2];
  bet11.reverse();
  // console.log(bet11)

  const allBet = (bet11) =>
    bet11.map((item) => (
      <div className="allbox" key={item._id}>
        <div className="topbox">
          <div className="title-topbox-orderlist">
            <div className="logo-topbox-orderlist">
              <img src={images[item.category]} alt="" className="logo-lotto" />
              <p style={{ marginLeft: "10px" }}>
                {item.category.toUpperCase()}
              </p>
            </div>
            <div className="title1-topbox-orderlist">order no: #{item._id}</div>
            <div className="date-topbox-orderlist">
              {formattedDate(item.createdAt)}
            </div>
          </div>
          <div className="status-topbox-orderlist">
            {item.status ? (
              <div className="in-status-topbox-orderlist yellow">
                ยังไม่ออกรางวัล
              </div>
            ) : (
              <div className="in-status-topbox-orderlist greenBg blackText">
                ออกรางวัลแล้ว
              </div>
            )}

            <Link to={`/orderlistdetail/${item._id}`}>
              <div className="in-status-topbox-orderlist burly">รายละเอียด</div>
            </Link>
            <div className="expdate-topbox-orderlist">ออกรางวัล</div>
            <div className="expdate-topbox-orderlist">
              {formattedDate(item.expDate)}
            </div>
          </div>
        </div>
        <div className="bottombox">
          <div className="price-bottombox-orderlist">
            <div className="text-price-orderlist">ยอดเล่น</div>
            <div className="price-orderlist">{sumBetAmount(item.betMe)} ฿</div>
          </div>
          <div className="money-bottombox-orderlist">
            <div className="text-money-orderlist">ยอดได้</div>
            {item.status ? (
              <div className="no-money-orderlist">0 ฿</div>
            ) : (
              <div className="win-money-orderlist">
                {calculateTotalBetAmount(item.betMe)} ฿
              </div>
            )}
          </div>
        </div>
      </div>
    ));

  return (
    <>
      <div className="layout-orderlist">
        <div className="relative">
          {/* <div className="topbox">
            <h3 className="font-700">ALL ORDER LIST အားလုံးအော်ဒါစာရင်း </h3>
          </div> */}

          <div className="container-orderlist">
            <div className="head-title">
              <div className="flex-1-history title-history">
                <p className="font-title dark-green"><span className="title-left-green">ALL ORDER LIST</span> အားလုံးအော်ဒါစာရင်း</p>
              </div>
            </div>
            <div className="title">
              <span className="title-history-left">โพยหวย</span>
            </div>

            {bet2 !== null ? (
              allBet(bet11)
            ) : (
              <div>
                <h1>Don't Have Order Yet</h1>
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}

export default OrderList;
