export function getExpDay(hour, min, sec) {
  const today = new Date();
  const expWeekDate = new Date(today);
  expWeekDate.setDate(today.getDate() + 1);
  expWeekDate.setHours(hour, min, sec, 0);
  return expWeekDate;
}

export function getExpWeek(dd, hh, mm, ss) {
  const today = new Date();
  const expWeekDate = new Date(today);
  expWeekDate.setHours(hh, 0, 0, 0)

  let daysUntilReset = dd - today.getDay();

  if (daysUntilReset < 0 || (daysUntilReset === 0 && today.getHours() >= hh)) {
    daysUntilReset += 7; // If today is the target day or past the target time, move to next week
  }

  expWeekDate.setDate(today.getDate() + daysUntilReset);
  return expWeekDate;
}

export function getExpMonth(day, hour, min, sec) {
  const today = new Date();
  const expMonthDate = new Date(today.getFullYear(), today.getMonth(), day, hour, min, sec, 0);
  if (today >= expMonthDate) {
    expMonthDate.setMonth(today.getMonth() + 1);
  }
  return expMonthDate;
}




export const getExpCategory = (categoryParams) => {

  function getExpWeek(dd, hh, mm, ss) {
    const today = new Date();
    const expWeekDate = new Date(today);
    expWeekDate.setHours(hh, 0, 0, 0)

    let daysUntilReset = dd - today.getDay();

    if (daysUntilReset < 0 || (daysUntilReset === 0 && today.getHours() >= hh)) {
      daysUntilReset += 7; // If today is the target day or past the target time, move to next week
    }

    expWeekDate.setDate(today.getDate() + daysUntilReset);

    // console.log(expWeekDate)
    // expWeekDate.setDate(today.getDate() + ((7 - today.getDay()) % 7) + dd);
    // expWeekDate.setHours(hh, mm, ss, 0);
    return expWeekDate;
  }
  // let category = categoryParams;
  // console.log(category);
  // typeof category;
  if (categoryParams === "cat") {
    return getExpWeek(1, 9, 0, 0);
  } else if (categoryParams === "dog") {
    return getExpWeek(1, 10, 0, 0);
  } else if (categoryParams === "cheetah") {
    return getExpWeek(1, 11, 0, 0);
  } else if (categoryParams === "penguin") {
    return getExpWeek(1, 12, 0, 0);
  } else if (categoryParams === "elephant") {
    return getExpWeek(1, 13, 0, 0);
  } else if (categoryParams === "bee") {
    return getExpWeek(2, 9, 0, 0);
  } else if (categoryParams === "fish") {
    return getExpWeek(2, 10, 0, 0);
  } else if (categoryParams === "monkey") {
    return getExpWeek(2, 11, 0, 0);
  } else if (categoryParams === "panda") {
    return getExpWeek(2, 12, 0, 0);
  } else if (categoryParams === "turtle") {
    return getExpWeek(2, 13, 0, 0);
  } else if (categoryParams === "bull") {
    return getExpWeek(3, 9, 0, 0);
  } else if (categoryParams === "cow") {
    return getExpWeek(3, 10, 0, 0);
  } else if (categoryParams === "fox") {
    return getExpWeek(3, 11, 0, 0);
  } else if (categoryParams === "hippopotamus") {
    return getExpWeek(3, 12, 0, 0);
  } else if (categoryParams === "ladybug") {
    return getExpWeek(3, 13, 0, 0);
  } else if (categoryParams === "bear") {
    return getExpWeek(4, 9, 0, 0);
  } else if (categoryParams === "dragon") {
    return getExpWeek(4, 10, 0, 0);
  } else if (categoryParams === "lion") {
    return getExpWeek(4, 11, 0, 0);
  } else if (categoryParams === "rhinoceros") {
    return getExpWeek(4, 12, 0, 0);
  } else if (categoryParams === "snake") {
    return getExpWeek(4, 13, 0, 0);
  } else if (categoryParams === "goldwing") {
    return getExpMonth(1, 15, 0, 0)
  } else if (categoryParams === "huaystar") {
    return getExpMonth(16, 15, 0, 0)
  } else if (categoryParams === "huaycrown") {
    return getExpMonth(28, 15, 0, 0)
  } else if (categoryParams === "huaykaren") {
    return getExpDay(16, 0, 0)
  } else {
    console.log("Noo");
  }
};


// get today format "yyyy-mm-dd"
export function getFormattedDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

// get dd/mm/yyyy format 
export const formatDate = (dateString) => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-GB', options);
};


export function getTimeConstant(category) {
  let nine = ["bee", "cat", "bull", "bear"]
  let ten = ["dog", "fish", "cow", "dragon"]
  let eleven = ["cheetah", "monkey", "fox", "lion"]
  let twelve = ["penguin", "panda", "hippopo", "rhinoceros"]
  let thirteen = ["elephant", "turtle", "ladybug", "snake"]
  if (nine.includes(category)) {
    return "09:00";
  } else if (ten.includes(category)) {
    return "10:00";
  } else if (eleven.includes(category)) {
    return "11:00";
  } else if (twelve.includes(category)) {
    return "12:00";
  } else if (thirteen.includes(category)) {
    return "13:00";
  } else {
    return "Not set Time"
  }
}


export const formattedDate = (isoDate) => {
  const date = new Date(isoDate);
  // Define the desired time format
  const options = {
    hour12: false, // Use 24-hour format
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return date.toLocaleString("en-US", options);
};








