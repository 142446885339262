import React from 'react'

const Forget1 = () => {
  return (
    <div>
    <div className="top-Register">
        <div className="title-Register">STEP 2</div>
    </div>
    <div className="box-center">
        <div className="box-info-Register">
            OTP send to Number 
            <br />
            0xxxxxxxxxx
        </div>
        <input type="number" className="info-Register" placeholder='OTP numbers' />
    </div>
    <div className="box-center">
        <div className="box-info-Register2">
            otp resend
        </div>
        
    </div>
</div>
  )
}

export default Forget1
