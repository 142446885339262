import {
  Route,
  Routes

} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Login from './features/auth/Login.jsx';
import Main from './features/auth/Main.jsx';
import Register from './features/auth/Register.jsx';
import PrivateRoute from './components/PrivateRoute.jsx';
import Layout from './components/Layout.jsx'

import 'react-toastify/dist/ReactToastify.css'
import ForgetPassword from './features/auth/ForgetPassword.jsx';
import Profile from './features/user/Profile.jsx';
import EditProfile from './features/user/EditProfile.jsx';
import Contact from './features/page/Contact.jsx';
import ListLotto from './features/page/ListLotto.jsx';
import Test from './features/page/Test.jsx';
import PlayLotto from './features/page/PlayLotto.jsx';
import WithDraw from './features/page/WithDraw.jsx';
import TopUp from './features/page/Topup.jsx';
import OrderList from './features/page/OrderList.jsx';
import Prize from './features/page/Prize.jsx';
import MemberDownline from './features/page/MemberDownline.jsx';
import Commission from './features/page/Commission.jsx';
import HowTo from './features/page/HowTo.jsx';
import History from './features/page/History.jsx';
import OrderListDetail from './components/OrderListDetail.jsx';
import Report from './features/page/Report.jsx';
import ScrollToTop from './components/ScrollToTop.jsx';



const App = () => {
  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>HUAY KAREN PPF4APP</title>
        <meta name="description" content="Charitable Activities for Ethnic Groups " />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="MM68APP-LOTTO" />
        <meta property="og:description" content="Charitable Activities for Ethnic Groups " />
        <meta property="og:url" content="https://ppf4app.com/" />
        <meta property="og:site_name" content="PPF4APP" />
      </Helmet>

      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path='/forgetpassword' element={<ForgetPassword />} />
          <Route index={true} path="/" element={<Login />} />
          <Route path='/test' element={<Test />} />

          {/* Private Routes */}
          <Route path='' element={<PrivateRoute />} >
            <Route path='/main' element={<Main />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/editprofile' element={<EditProfile />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/listlotto' element={<ListLotto />} />
            <Route path='/topup' element={<TopUp />} />
            <Route path='/withdraw' element={<WithDraw />} />
            <Route path='/playlotto/:categoryParams' element={<PlayLotto />} />
            <Route path='/orderlist/' element={<OrderList />} />
            <Route path='/orderlistdetail/:itemParams' element={<OrderListDetail />} />
            <Route path='/prize' element={<Prize />} />
            <Route path='/memberdownline' element={<MemberDownline />} />
            <Route path='/commission' element={<Commission />} />
            <Route path='/howto' element={<HowTo />} />
            <Route path='/history' element={<History />} />
            {/* <Route path='/test' element={<Test />} /> */}
            <Route path='/report/:categoryParams' element={<Report />} />


          </Route>
        </Route>
      </Routes>


    </HelmetProvider>
  )

}
export default App
