import React from 'react'
import { Spinner } from 'react-bootstrap'

export const Loader1 = () => {
  return (
    <Spinner 
    animation='border'
    role='status'
    style={{
      width: '10px',
      height: '10px',
      margin: 'auto',
      display: 'block',
    }}
    />
  )
}