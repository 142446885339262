
const Footer = () => {
  return (
    <div className='Footer'>
      <p className="font08-footer">
        2023 Copyight @
        <span className='color-wed-footer'> PPF4APP.COM </span>
      ALL RIGHTS RESERVED
      </p>
    </div>
  )
}

export default Footer
