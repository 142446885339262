import React from "react";
import BottomNav from "../../components/BottomNav";

function Commission() {
  return (
    <>
      <div className="layout-history">
        <div className="relative">
          <div className="container-history">
            <div className="head-title">
              <div className="flex-1-history title-history">
                <p className="font-title dark-green"><span className="title-left-green">Commissiton List</span> ကော်မရှင်စာရင်း</p>
              </div>
            </div>
            <div className="title">
              <span className="title-history-left">รายการค่าคอมมิชชั่น</span>
            </div>
          </div>
          <br />
          <br />
          <h4 style={{ textAlign: "center" }}>ยังไม่มีรายการค่าคอมมิชชั่น</h4>
          {/* <div className="box-history">
                        <div className="boxbox-history">
                            <div className="inbox-history">
                                <div className="inbox-detail-history">
                                    <div className="flex-1-history">ถูกหวย</div>
                                    <div className="right-history">
                                        <div className="green-history">+ 42.00 ฿</div>
                                        <div className="flex-history">
                                            <div className="font-06-history">
                                            08-01-2023 10:33:46 น.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="boxbox-history">
                            <div className="inbox-history">
                                <div className="inbox-detail-history">
                                    <div className="flex-1-history">ซื้อหวย</div>
                                    <div className="right-history">
                                        <div className="red-history">- 10.00 ฿</div>
                                        <div className="flex-history">
                                            <div className="font-06-history">
                                            08-01-2023 11:23:26 น.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="boxbox-history">
                            <div className="inbox-history">
                                <div className="inbox-detail-history">
                                    <div className="flex-1-history">ซื้อหวย</div>
                                    <div className="right-history">
                                        <div className="red-history">- 10.00 ฿</div>
                                        <div className="flex-history">
                                            <div className="font-06-history">
                                            08-01-2023 11:23:26 น.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>

      <BottomNav />
    </>
  );
}

export default Commission;
