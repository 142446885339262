import React from "react";
import logoKbank from "../../images/logoktb.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Badge } from "react-bootstrap";

function TopUp() {
  const { userInfo } = useSelector((state) => state.auth);
  const { balance1 } = useSelector((state) => state.balance0);

  return (
    <div className="layout-main-Topup">
      <div className="relative-Topup">
        <div className="box-wallet-Topup">
          <p className="font-title">
            <span className="title-left-green">TOPUP</span> သင့်အကောင့်ကို
            ငွေဖြည့်ပါ။
          </p>
          <p>
            <span className="title-history-left">เติมเงินเข้าบัญชี :</span>
          </p>
          <div className="container-box-Topup">
            <div className="box-icon-topup">
              <FontAwesomeIcon icon={faAddressCard} className="icons-Home" />
              {/* <FontAwesomeIcon icon={faUser} className="icons-Home" /> */}
              {/* <i className="fa fa-address-card" style="font-size: 32px;"></i> */}
            </div>
            <div>
              <div className="color-Topup">
                Name : {userInfo ? userInfo.name : ""}
              </div>
              <div className="box-font08-Topup">
                <div>{userInfo ? userInfo.phone : ""}</div>
                <div>{balance1 ? balance1.balance : 0} ฿</div>
              </div>
            </div>
          </div>
          {/* <div className="right-Topup">ข้อมูล ณ เวลา xx:xx น.</div> */}
        </div>
      </div>
      <div style={{ height: "210px" }}></div>
      <div className="container-txt-Topup">
        <h6>အရေးကြီးတာက ပိုက်ဆံမထည့်ခင် ဖတ်ပါ။</h6>
        <Badge pill bg="success">
          <h6 className="noMB"> สำคัญ โปรดอ่านก่อนทำการเติมเงิน!! </h6>
        </Badge>
        <br />
        <div className="margin-Topup">
          <div className="title-border">
            <span className="circle-yellow">1.</span>{" "}
            สามารถเติมเงินเครดิตขั้นต่ำได้ครั้งละ 20.- <br />
            และเติมเงินเครดิตเข้าระบบ ได้สูงสุดตามความต้องการ 
            ตามงบประมาณที่ต้องการเล่น <br />
            <br /> တစ်ကြိမ်လျှင် အနည်းဆုံး 20.- ခရက်ဒစ်ဖြင့် ငွေဖြည့်နိုင်ပြီး
            လိုအပ်သလို အများဆုံးအထိ စနစ်ထဲသို့ ခရက်ဒစ်ငွေဖြည့်နိုင်သည်။ 
            သင်ကစားလိုသောဘတ်ဂျက်အတိုင်း
          </div>
          {/* <div style={{ fontSize: '16px' }}>2. ต้องโอนเงินภายในเวลาที่กำหนด และ กรอกจำนวนเงินถูกต้องตามที่กำหนดให้เท่านั้น <span style={{ color: 'red' }}>( หากเติมเงินด้วย QR Code จะไม่จำเป็นต้องกรอกทศนิยมด้วยตัวเอง )</span>
                        </div> */}
          <div className="title-border">
            <span className="circle-yellow">2.</span>{" "}
            คัดลอกเลขบัญชีธนาคารที่ปรากฎในแอป<br />และทำการโอนเงิน เติมเครดิตเข้าระบบแอป โปรดทำการตรวจสอบ ข้อมูลการโอน และจำนวนเงินที่ถูกต้อง ก่อนกดยืนยันการโอนเงินทุกครั้ง{" "}
            <br />
            <br />
            ေါ်လာသော ဘဏ်အကောင့်နံပါတ်ကို ကူးယူပါ။ အက်ပ်နှင့်
            ခရက်ဒစ်ငွေဖြည့်သွင်းငွေများကို အက်ပ်စနစ်သို့ လွှဲပြောင်းပါ။
            ငွေလွှဲသည့်အချိန်တိုင်းကို အတည်ပြုရန် မနှိပ်မီ
            ငွေလွှဲအချက်အလက်များနှင့် ပမာဏမှန်ကန်ကြောင်း စစ်ဆေးပါ။
          </div>
          <div className="title-border">
            <span className="circle-yellow">3.</span> ส่งหลักฐานการโอนเงิน ( Pay
            in ) ไปที่ Line Official  เมื่อทีมงาน Admin
            ตรวจสอบแล้วจะเติมเครดิตเข้าระบบภายในระยะเวลาประมาณ 5 นาที <br />
            <br />
            ငွေလွှဲခြင်းအထောက်အထား (Pay in) ကို Line Official သို့ ပေးပို့ပါ။
            Admin အဖွဲ့မှ စစ်ဆေးပြီးသည်နှင့် ၅ မိနစ်ခန့်အတွင်း စနစ်ထဲသို့
            ခရက်ဒစ် ထည့်ပေးပါမည်။
          </div>
          <div className="title-border">
            <span className="circle-yellow">4.</span>{" "}
            กรณีเงินเครดิตยังไม่เข้าระบบ ภายในระยะเวลาที่กำหนด ให้ทำการแจ้งทีมงาน
            Admin อีกครั้ง เพื่อดำเนินการแจ้งปัญหาผ่านทาง Line@ คลิก หรือที่เมนู
            ติดต่อเรา **** <br />
            <br />
            အချိန်ကာလ အတွင်း ချေးငွေစနစ်သို့ ငွေမသွင်းရသေးသည့်က...
          </div>
          <div className="title-border">
            <span className="circle-yellow">5.</span>{" "}
            ก่อนโอนเงินเติมเครดิตทุกครั้ง ให้ตรวจสอบข้อมูล
            บัญชีธนาคาร ที่ปรากฎหน้าแอปก่อนการโอน (ไม่แนะนำให้บันทึกข้อมูล บัญชีธนาคาร เป็นบันทึกรายการโปรด
            เนื่องจากแอป จะเปลี่ยนข้อมูลบัญชี ธนาคารเป็นระยะในแต่ละช่วง) <br />
            <br />
            ငွေမလွှဲမီ သင့်ခရက်ဒစ်ကို အချိန်တိုင်း ငွေဖြည့်ပါ။  အချက်အလက်ကို
            စစ်ဆေးပါ။  လွှဲပြောင်းခြင်းမပြုမီ အက်ပ်ပေါ်တွင် ပေါ်လာသော ဘဏ်အကောင့်
            (ဘဏ်စာရင်းအချက်အလက်ကို စိတ်ကြိုက်အဖြစ် သိမ်းဆည်းရန်
            အကြံပြုထားခြင်းမရှိပါ။  အကြောင်းမှာ အပလီကေးရှင်းသည်
            စက်ရှင်တစ်ခုစီတွင် သင့်ဘဏ်အကောင့်အချက်အလက်ကို အခါအားလျော်စွာ
            ပြောင်းလဲပေးသောကြောင့်ဖြစ်သည်။)
          </div>
        </div>
      </div>

      <div className="bottom-line-Topup">
        <span className="title-history-left">เติมเงินเข้าบัญชี :</span>
        <div className="title-flex-1-Topup"></div>
      </div>
      <div
        style={{
          padding: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div className="topup-img-card-selected">
          {/* <img
            src={logoKbank}
            alt="topupQr"
            style={{ width: "50%", padding: "10px" }}
          /> */}
          <br />
          <p
            className="noMB"
            style={{
              color: "rgb(255,255,255)",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            {/* ธนาคารกรุงไทย */}
            .......
          </p>
          <p
            className="noMB"
            style={{
              color: "rgb(255,255,255)",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Account Name / ชื่อบัญชี
          </p>
          <p
            className="noMB"
            style={{
              color: "rgb(255,193,7)",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            {/* Mr. KYAW SOE  */}
            ........ ........
          </p>
          <p
            className="noMB"
            style={{
              color: "rgb(255,255,255)",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            A/C No.เลขที่บัญชี{" "}
          </p>
          <p
            className="noMB"
            style={{
              color: "rgb(255,193,7)",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            {/* 662-9-85672-4 */}
            xxx-x-xxxxx-x
          </p>
        </div>
      </div>
      {/* <div className="bottom-line-Topup">
                    <div>เลือกจำนวนเงิน</div>
                    <div className="title-flex-1-Topup"></div>
                </div> */}
      {/* <div className="box-number-Topup">
                    <input className="number-Topup" type="button" value="300" />
                    <input className="number-Topup" type="button" value="500" />
                    <input className="number-Topup" type="button" value="800" />
                    <input className="number-Topup" type="button" value="1,000" />
                    <input className="number-Topup" type="button" value="1,500" />
                    <input className="number-Topup" type="button" value="2,000" />
                </div> */}
      {/* <div>
                    <div className="box-input-Topup">ระบุจำนวนเงิน</div>
                    <input type="tel" maxlength="6" className="input-Topup" placeholder="ระบุจำนวนเงิน" />
                </div> */}

      {/* <div className="box-button-Topup">
                    <button className="btnCal-Topup">ยกเลิก</button>
                    <button className="btnSuc-Topup">ยืนยัน</button>
                </div> */}
    </div>
  );
}

export default TopUp;
