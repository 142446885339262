import React, { useEffect} from 'react'
import { useState } from 'react';
import { Input } from '../Component.style'
import { useDispatch } from 'react-redux';
import { addBet } from '../../slices/betSlice';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddBetRates from "../AddBetRates";


const OtpNum2 = ({newBetType}) => {

    const dispatch = useDispatch();
    
    
    // console.log(newBetType)

    const [num, setNum] = useState(new Array(2).fill(""));
    // console.log(num)

    let number = '';
    for(let i=0;i<num.length;i++){
        number = number + num[i];
     }
    //  console.log(number)
     


    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setNum([...num.map((d, idx) => (idx === index ? element.value : d))]);


        if (element.nextSibling) {
            // console.log(element.nextSibling.id)
            element.nextSibling.focus();
        }
    };

    const handleClick = (value, index) => {

        
        const index1 = num.findIndex((el) => el === "");
        num[index1] = value;


        setNum([...num.map((d, idx) => (idx === index ? value : d))])

    }

    useEffect(()=>{

  
    // console.log(number.length)
    if(number.length === 2){
        setTimeout(() => {
            
            // console.log("hi")

            // const newBetArray = JSON.parse(localStorage.getItem('newBetArray')) || [];

                // console.log(newBetArray);
            
                const newBet =  {betType: newBetType, betNum: number, betAmount: 1}

                dispatch(addBet(newBet))
                
                // console.log(setNewBet)
            
                // let newBetArray1 = [...newBetArray, setNewBet]
    
                // localStorage.setItem('newBetArray', JSON.stringify(newBetArray1));
    
                setNum(new Array(2).fill(""));

                
            }, 500);
            

        }else{
            console.log("Noo!")
        }
    

    },[number,dispatch,newBetType])
    


    const backClick = (value, index) => {
        console.log(value);
        const index1 = num.findIndex((el) => el === "");
        if (index1 <= 0) {
            let index2 = num.length - 1;
            num[index2] = value;
        } else {
            let index3 = index1 - 1;
            num[index3] = value;
        }

        setNum([...num.map((d, idx) => (idx === index ? value : d))])
    }
    return (
        <>
                <div className="box-click-LottoPlay">
                    <div style={{ display: 'flex', placeContent: 'center', alignItems: 'center' }}>
                        <div className="" data-testid="otp-input-root" style={{ display: "flex" }}>
                            {/* num */}
                            {num.map((data, index) => {
                                return (
                                    <Input
                                        // id={index}
                                        className="otp-field"
                                        disabled=""
                                        type="tel"
                                        name="otp"
                                        maxLength="1"
                                        key={index}
                                        value={data}
                                        onChange={e => handleChange(e.target, index)}
                                        onFocus={e => e.target.select()}
                                    />
                                );
                            })}


                            {/* <Input type="tel" maxlength="1" disabled="" className="" data-testid="input" value="" />
                        <Input type="tel" maxlength="1" disabled="" className="" data-testid="input" value="" /> */}
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        placeContent: 'center',
                        alignItems: 'center'
                    }}>
                    </div>
                    <div style={{ height: "15px" }}></div>
                    <div className="center-LottoPlay">
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("1")} value="1" type="button" />
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("2")} value="2" type="button" />
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("3")} value="3" type="button" />

                    </div>
                    <div className="center-LottoPlay">
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("4")} value="4" type="button" />
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("5")} value="5" type="button" />
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("6")} value="6" type="button" />
                    </div>
                    <div className="center-LottoPlay">
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("7")} value="7" type="button" />
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("8")} value="8" type="button" />
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("9")} value="9" type="button" />
                    </div>
                    <div className="center-LottoPlay flex-LottoPlay">
                        <input className='btn-number-play-LottoPlay' onClick={e => setNum([...num.map(v => "")])} value="clear" type="button" />
                        <input className='btn-number-play-LottoPlay' onClick={(e) => handleClick("0")} value="0" type="button" />
                        <input className='btn-number-play-LottoPlay' onClick={(e) => backClick("")} value="X" type="button" />
                        {/* <input className="btn-number-play-LottoPlay-1" type="button" disabled value="clear" />
                    <input className="btn-number-play-LottoPlay" type="button" value="0" />
                    <input className="btn-number-play-LottoPlay" type="button" value="X" /> */}
                        {/* <div className="btn-number-play-LottoPlay">
                                                    <i className="fa fa-window-close">x</i>
                                                </div> */}
                    </div>
                </div>
                {/* <div className="playBtn">
                    <button className="btn-nonesentpost-LottoPlay" onClick={e => setNum([...num.map(v => "")])}>ล้างข้อมูล</button>
                    <button className="btn-sentpost-LottoPlay" onClick={() => setShow(true)} >ใส่ราคา</button>
                </div> */}
        </>
    )
}

export default OtpNum2
