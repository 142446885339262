import React from "react";
import BottomNav from "../../components/BottomNav";
import { useSelector } from "react-redux";

function MemberDownline() {
  const { userInfo } = useSelector((state) => state.auth);
  let dLine = userInfo.downLine;
  const dLineList = (dLine) =>
    dLine.map((item)=>(
      <div className="boxbox-history" key={item._id}>
          <div className="inbox-history">
            <div className="inbox-detail-history">
              <div className="flex-1-history" >
                {item.name} : {item._id}
                </div>
            </div>
          </div>
        </div>
    ))
  console.log(dLine)
  

  return (
    <>
      <div className="layout-history">
        <div className="relative">
          <div className="container-history">
            <div className="head-title">
              <div className="flex-1-history title-history">
                <p className="font-title dark-green"><span className="title-left-green">Downline</span> အဖွဲ့ဝင်များ</p>
              </div>
            </div>
            <div className="title">
              <span className="title-history-left">สมาชิกดาวน์ไลน์</span>
            </div>
          </div>
          <div className="box-history">
          {/* <h4 style={{ textAlign: "center" }}>ยังไม่มีสมาชิกดาวน์ไลน์</h4> */}
            {dLine.length > 0 ? dLineList(dLine): "ยังไม่มีสมาชิกดาวน์ไลน์" }
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}

export default MemberDownline;
