import EndMonthTime from "../../components/EndMonthTime";
import "./Test.css";





// const winningBets = betMe
//   .filter((bet) => bet.checkWin === true)
//   .map((bet) => ({
//     betType: bet.betType,
//     betAmount: bet.betAmount,
//   }));

// console.log(winningBets);



// Create an array to store the objects
// const betTypesWithAmount = [];

// Iterate over the properties of arr3 and create objects
// for (const betType in arr3) {
//   if (arr3.hasOwnProperty(betType)) {
//     const betAmount = arr3[betType];
//     betTypesWithAmount.push({ betType, betAmount });
//   }
// }

// console.log(betTypesWithAmount);

// function getReturnByKey(key) {
//   return arr3[key];
// }



const Test = () => {
  return (
    <>
      <div className="layout-Login">
        <div className="relative-login">
          <div className="container-login ">
            {/* content here */}

            Test

            {/* content end */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Test;
