import React from 'react'
import { useState } from 'react';
import OtpNum1 from '../components/otpnumber/OtpNum1';
import OtpNum2 from '../components/otpnumber/OtpNum2';
import OtpNum3 from '../components/otpnumber/OtpNum3';

const NumberLotto11 = ({betType}) => {
    const [numPattern, setNumPattern] = useState(1)
    const [selected, setSelected] = useState(false)

    const patternDisplay = ()=> {
        if(numPattern === 0) {
            return <OtpNum1 newBetType={newBetType} />
        } else if (numPattern === 1){
            return <OtpNum2 newBetType={newBetType} />
        } else  {
            return <OtpNum3 newBetType={newBetType} />
        }
    }

    let newBetType = betType+numPattern;
    console.log(newBetType)

    return (
        <>
            <div className="box-number-LottoPlay">
                <div className="line-bottom-LottoPlay">รูปแบบเลข</div>
                <div style={{ height: '20px' }}></div>
                <div className=""><div className="flex-1-LottoPlay flex-LottoPlay flex-wrap-LottoPlay">
                    <button 
                    className={selected === false? "btn-select-play-LottoPlay flex-1-LottoPlay left-border-LottoPlay btn-select-play-action-LottoPlay right-border-LottoPlay": "btn-select-play-LottoPlay flex-1-LottoPlay left-border-LottoPlay right-border-LottoPlay"}
                    onClick={()=> {
                        setNumPattern(1)
                        setSelected(false)
                    }} 
                    >ตรง</button>
                    
                </div></div>
                <div style={{ height: '20px' }}>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="font-09-LottoPlay">กรอกตัวเลขที่ต้องการแทง</div>
                </div>
                {/* <OtpNum1 /> */}
                {/* <OtpNum2 /> */}
                {/* <OtpNum3 /> */}
                {patternDisplay()}

            </div>
        </>
    )
}

export default NumberLotto11
