import React from 'react'

const Forget2 = () => {
  return (
    <div>
            <div className="top-Register">
                <div className="title-Register">STEP 3</div>
            </div>
            <div className="box-center">
                <div className="box-info-Register">
                   Enter new Password
                </div>
                <input type="text" className="info-Register" placeholder='Password' />
            </div>
            <div className="box-center">
                <div className="box-info-Register">
                    Password confirm
                </div>
                <input type="password" className="info-Register" placeholder='Password Confirm' />
            </div>
            <div className="box-center">
                <div className="box-info-Register2">
                    info:infomation
                </div>
                
            </div>
        </div>
  )
}

export default Forget2
