import React, { useState, useEffect } from 'react';

// dd mean 0:sum 1:mon 2:tue 3:wed 4:thu 5:fri 6:sat
const CountdownResetWeekly = ({dd,hh,mm,ss}) => {
    const [nextResetDate, setNextResetDate] = useState(null);

    useEffect(() => {
        // Set the target day and time (Wednesday at 10:00 AM)
        const targetDay = dd; // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
        const targetHour = hh;
    
        // Function to calculate the next reset date
        const calculateNextResetDate = () => {
          const now = new Date();
          const targetDate = new Date();
    
          targetDate.setHours(targetHour, 0, 0, 0);
    
          let daysUntilReset = targetDay - now.getDay();
          
          if (daysUntilReset < 0 || (daysUntilReset === 0 && now.getHours() >= targetHour)) {
            daysUntilReset += 7; // If today is the target day or past the target time, move to next week
          }
    
          targetDate.setDate(now.getDate() + daysUntilReset);
    
          return targetDate;
        };
    
        // Calculate and set the next reset date
        const nextResetDate = calculateNextResetDate();
        setNextResetDate(nextResetDate);
    
        // Update next reset date every second
        const intervalId = setInterval(() => {
            const now = new Date();
          const nextResetDate = calculateNextResetDate();
          setNextResetDate(nextResetDate);
    
          // If it's time to reset, you can perform the reset logic here
          if (nextResetDate <= now) {
            // Reset logic goes here
            console.log('Resetting...');
          }
        }, 1000);
    
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, [dd,hh]); 

      const now = new Date();
      const timeUntilReset = nextResetDate ? nextResetDate - now : 0;
  // Helper function to format the time remaining as HH:mm:ss
  function formatTime(time) {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  return (
    <>{formatTime(timeUntilReset)}</>
  );
};

export default CountdownResetWeekly;
