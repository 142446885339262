import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    balance1: sessionStorage.getItem('balance')
        ? JSON.parse(sessionStorage.getItem('balance'))
        : null,
    balance2: null
};


const balanceSlice = createSlice({
    name: 'balance0',
    initialState,
    reducers: {
        setBalance: (state, action) => {
            state.balance1 = {balance: action.payload};
            sessionStorage.setItem('balance', JSON.stringify(action.payload));
          },
        setBalance1: (state, action) => {
            state.balance1 = action.payload;
            sessionStorage.setItem('balance', JSON.stringify(action.payload));
          },
        setBalance2: (state, action) => {
            state.balance2 = action.payload;
        }

    }
})

export const { setBalance, setBalance1, setBalance2 } = balanceSlice.actions;

export default balanceSlice.reducer;