import React, { useState, useEffect } from 'react';

// dd mean 0:sum 1:mon 2:tue 3:wed 4:thu 5:fri 6:sat
const CountdownResetDay = ({hh,mm,ss}) => {
  // console.log({dd},{hh},{mm})
  const [timeRemaining, setTimeRemaining] = useState(getNextDayStartDate(hh,mm,ss));
  // const EndTime = getNextDayStartDate(dd,hh,mm,ss)
  // console.log(EndTime)
  // Helper function to get the start date of the next Day
  function getNextDayStartDate(hour,min,sec) {
    const now = new Date();
    const nextDayStartDate = new Date(now);
    nextDayStartDate.setHours(hour, min, sec, 0);
    return nextDayStartDate;
  }

  useEffect(() => {
    const countdownEndTime = getNextDayStartDate(hh,mm,ss); // Calculate countdown end time

    // Calculate the initial time remaining
    const now = new Date();
    if(now >= countdownEndTime){
      countdownEndTime.setDate(countdownEndTime.getDate()+1);
    }
    const difference = countdownEndTime - now;
    setTimeRemaining(difference);

    // Set up the interval to update the countdown every second
    const interval = setInterval(() => {
      const newDifference = countdownEndTime - new Date();
      setTimeRemaining(newDifference);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [hh,mm,ss]);

  // Helper function to format the time remaining as HH:mm:ss
  function formatTime(time) {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  return (
    <>{formatTime(timeRemaining)}</>
  );
};

export default CountdownResetDay;
