import React, { useState } from "react";

import { Badge, Button, Modal } from "react-bootstrap";
import BottomNav from "../../components/BottomNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBookOpenReader,
  faCartShopping,
  faCircleQuestion,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
} from "@fortawesome/free-solid-svg-icons";

function HowTo() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  return (
    <>
      <div className="layout-history">
        <div className="relative">
          <div className="container-history">
            <div className="head-history">
              <div className="flex-1-history title-howto">
                <div className="font-title-howto">
                  วิธีการใช้งาน / အသုံးပြုနည်း
                </div>
              </div>
            </div>
          </div>

          <div className="box-howto">
            <div className="flex-1-history pointer" onClick={handleShow}>
              <div className="font-size-14">
                <Badge pill bg="warning" text="dark">
                  คู่มือการใช้งาน
                </Badge>
                <br />
              </div>
              ညွှန်ကြားချက်လက်စွဲ
            </div>
            <div className="flex-1-history1">
              {/* <div className="icon-lists-Home"> */}
              <FontAwesomeIcon
                icon={faBookOpenReader}
                className="icons-HowTo"
              />
              {/* <i className="fa fa-trophy icons-Home"></i> */}
              {/* </div> */}
            </div>
          </div>
          <div className="box-howto">
            <div className="flex-1-history pointer" onClick={handleShow1}>
              <div className="font-size-14">
                <Badge pill bg="warning" text="dark">
                  วิธีการสมัคร
                </Badge>
                <br />
              </div>
              လျှောက်ထားနည်း
            </div>
            <div className="flex-1-history1">
              <FontAwesomeIcon icon={faAddressCard} className="icons-HowTo" />
            </div>
          </div>
          <div className="box-howto">
            <div className="flex-1-history pointer" onClick={handleShow2}>
              <div className="font-size-14">
                <Badge pill bg="warning" text="dark">
                  วิธีการเติมเงิน
                </Badge>
                <br />
              </div>
              ငွေဖြည့်နည်း
            </div>
            <div className="flex-1-history1">
              <FontAwesomeIcon
                icon={faMoneyBillTransfer}
                className="icons-HowTo"
              />
            </div>
          </div>
          <div className="box-howto">
            <div className="flex-1-history pointer" onClick={handleShow3}>
              <div className="font-size-14">
                <Badge pill bg="warning" text="dark">
                  วิธีการถอนเงิน{" "}
                </Badge>
                <br />
              </div>
              ငွေထုတ်နည်း
            </div>
            <div className="flex-1-history1">
              <FontAwesomeIcon
                icon={faMoneyBillTrendUp}
                className="icons-HowTo"
              />
            </div>
          </div>
          <div className="box-howto">
            <div className="flex-1-history pointer" onClick={handleShow4}>
              <div className="font-size-14">
                <Badge pill bg="warning" text="dark">
                  วิธีการซื้อหวย
                </Badge>
                <br />
              </div>
              ထီထိုးနည်း
            </div>
            <div className="flex-1-history1">
              <FontAwesomeIcon icon={faCartShopping} className="icons-HowTo" />
            </div>
          </div>
          <div className="box-howto">
            <div className="flex-1-history pointer" onClick={handleShow5}>
              <div className="font-size-14">
                <Badge pill bg="warning" text="dark">
                  คำถามที่พบบ่อย
                </Badge>
                <br />
              </div>
              အမြဲမေးလေ့ရှိသောမေးခွန်းများ
            </div>
            <div className="flex-1-history1">
              <FontAwesomeIcon
                icon={faCircleQuestion}
                className="icons-HowTo"
              />
            </div>
          </div>
        </div>
      </div>
      {/* modal show */}
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="font16">
              <span className="font-title-howto">คู่มือการใช้งาน</span>{" "}
              အသုံးပြုသူလက်စွဲ
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-howto">
            <p>
              <span className="circle">1</span>
              <br />
              <br /> ผู้เล่นสามารถเล่นได้ผ่าน www.PPF4app.com
              โดยใช้เบอร์มือของตนในขั้นตอนการสมัคร (รองรับทุกประเทศทั่วโลก)
              <br />
              <br />
              <div className="yellow p5">
              ကစားသမားတွေကတဆင့် ကစားနိုင်ပါတယ်။  www.PPF4app.com 
              လျှောက်လွှာလုပ်ငန်းစဉ်တွင် သင့်လက်နံပါတ်ကို အသုံးပြု၍ 
              (ကမ္ဘာတစ်ဝှမ်းရှိနိုင်ငံအားလုံးကိုထောက်ခံသည်)
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">2</span>
              <br />
              <br />
              ผู้เล่นจะต้องมีสมุดบัญชีธนาคารของประเทศไทยเท่านั้นในการสมัคร
              เพื่อความสะดวกในการเติมเงินเครดิตและรับเงินถูกรางวัล
              <br />
              <br />
              <div className="yellow p5">
              ကစားသမားများသည် လျှောက်ထားရန်အတွက်သာ
              ထိုင်းဘဏ်အကောင့်စာအုပ်ရှိရပါမည်။  ခရက်ဒစ်ထည့်ခြင်းနှင့်
              အောင်ပွဲဝင်ငွေလက်ခံခြင်းအတွက် အဆင်ပြေစေရန်။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">3</span>
              <br />
              <br />
              ผู้เล่นจะต้องเติมเงินขั้นต่ำครั้งละ 20.-THB <br />
              ในการร่วมสนุกเสี่ยงโชคในกิจกรรมกุศลนี้ <br />
              แต่สามารถแทงได้ขั้นต่ำครั้งละ 1.- THB ต่อเบอร์ สูงสุดไม่เกิน
              2,000.- THB ต่อเบอร์ ในแต่ละหมวดที่เปิดรับแทง
              <br />
              <br />
              <div className="yellow p5">
              ကစားသူများသည် ဤပရဟိတလှုပ်ရှားမှုတွင် ပျော်ရွှင်စွာပါဝင်ပြီး
              လောင်းကစားပြုလုပ်ရန် တစ်ကြိမ်လျှင် အနည်းဆုံး 20.-THB
              ငွေဖြည့်ရပါမည်။  သို့သော် လောင်းကြေးဖွင့်သည့်အမျိုးအစားတစ်ခုစီတွင်
              နံပါတ်တစ်ခုလျှင် အများဆုံး 2,000.- THB ထက်မပိုသော တစ်ကြိမ်လျှင်
              အနည်...
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">4</span>
              <br />
              <br />
              แนะนำให้ผู้เล่นเติมเงินเครดิตในแต่ละครั้งตามงบประมาณที่ต้องการร่วมสนุกเสี่ยงโชคให้พอดีในแต่ละหมวด
              <br />
              <br />
              <div className="yellow p5">
              ကစားသမားများသည် ပျော်ရွှင်စရာနှင့် လောင်းကစားတွင် ပါဝင်လိုသော
              ဘတ်ဂျက်အလိုက် အကြိမ်တိုင်း ခရက်ဒစ်ငွေဖြည့်ရန် အကြံပြုထားသည်။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">5</span>
              <br />
              <br />
              สำหรับผู้เล่นที่โชคดีถูกรางวัลทางโครงการฯจะโอนเงินรางวัลให้ภายในเวลา
              15 นาทีนับจากที่ประกาศผลรางวัล
              ไปยังบัญชีธนาคารของลูกค้าที่ใช้ในการสมัครแบบอัตโนมัติ
              ผู้เล่นสามารถถอนได้ทันทีแบบไม่มีขั้นต่ำ <br />
              <br />
              <div className="yellow p5">
              ဆုရရှိကံကောင်းသောကစားသမားများအတွက်၊ ပရောဂျက်သည် ဆုကြေငြာပြီးနောက်
              15 မိနစ်အတွင်း ဆုလွှဲပြောင်းပေးမည်ဖြစ်သည်။  အလိုအလျောက်
              မှတ်ပုံတင်ရန်အတွက် အသုံးပြုသည့် ဖောက်သည်၏ ဘဏ်အကောင့်သို့ 
              ကစားသမားများသည် အနိမ့်ဆုံးမရှိဘဲ ချက်ချင်းပြန်ထုတ်နိုင်သည်။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">6</span>
              <br />
              <br />
              ทางโครงการฯขอสงวนสิทธิ์ไม่อนุญาตให้กลุ่มชาติพันธุ์ที่ยังไม่บรรลุนิติภาวะเล่นกิจกรรมเสี่ยงโชค
              ภายใน www.PPF4app.com โดยเด็ดขาด
              <br />
              <br />
              <div className="yellow p5">
              ပရောဂျက်သည် www.PPF4app.com အတွင်းရှိ အသက်မပြည့်သေးသည့်
              တိုင်းရင်းသားအုပ်စုများအား လောင်းကစားကစားခြင်းအား တင်းတင်းကျပ်ကျပ်
              တားမြစ်ပိုင်ခွင့်ရှိသည်။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">7</span>
              <br />
              <br />
              ผู้เล่นรับทราบเป็นอย่างดีว่ากิจกรรมกุศลนี้เป็นการจัดขึ้นเพื่อหารายได้ในการช่วยเหลือกลุ่มชาติพันธุ์โดยผู้เล่นมีความประสงค์ที่ต้องการร่วมสนับสนุนกิจกรรมในครั้งนี้
              และทางโครงการฯขอขอบคุณสำหรับ "กลุ่มผู้บริจาคเงิน"
              ทุกท่านที่ได้บริจาคเงินโดยตรง
              เพื่อให้กิจกรรมกุศลบรรลุเป้าหมายตามที่กำหนดไว้โดยเร็วที่สุด
              <br />
              <br />
              <div className="yellow p5">
              တိုင်းရင်းသားလူမျိုးစုများကိုကူညီရန် ဤအလှူပွဲသည်
              ရန်ပုံငွေရှာဖွေရန်စီစဉ်ထားခြင်းဖြစ်သည်ကို ကစားသမားများ
              ကောင်းစွာသိရှိနားလည်ပါသည်။ ကစားသမားများသည် ဤလှုပ်ရှားမှုကို
              ပံ့ပိုးကူညီရန် ရည်ရွယ်ပါသည်။  ပရောဂျက်အတွက် ကျေးဇူးတင်ကြောင်း
              ပြောကြားလိုပါသည်။  "အလှူရှင် ရန်ပုံငွေအဖွဲ့"- တိုက်ရိုက်
              လှူဒါန်းခဲ့ကြသူများ အားလုံး။  ပရဟိတ လုပ်ငန်းများ ဆောင်ရွက်ရာတွင်
              မိမိတို့ ရည်မှန်းထားသော ပန်းတိုင်များ အမြန်ဆုံး အောင်မြင်စေရန်။
              </div>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal1 show1 */}
      <Modal show={show1} onHide={handleClose1} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="font16">
              <span className="font-title-howto">วิธีการสมัคร</span>{" "}
              လျှောက်ထားနည်း
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-howto">
            <p>
              <span className="circle">1</span>
              <br />
              <br /> เข้า Google ค้นชื่อ www.PPF4app.com <br /> 
              กดเข้าไปในเว็บ (สามารถกดคลิกปุ่มจุด 3 จุด) อยู่ด้านบนขวามือของเว็บ แล้วคลิกไปที่เมนูติดตั้งแอป (Install app) เพียงแค่นี้แอป PPF4 
              จะปรากฎอยู่ที่หน้าจอมือถือ (Smart Phone) เพิ่มความสะดวกในการใช้งานในครั้งต่อไป
              <br />
              <br />
            
            <div className="yellow p5">
              Google သို့သွား၍ www.PPF4app.com အမည်ကို ရှာဖွေပါ။  ဝဘ်ဆိုက်ကို
              ဝင်ရောက်ရန် နှိပ်ပါ (ဝဘ်ဆိုဒ်၏ ညာဘက်အပေါ်ရှိ အစက် ၃ ခုကို
              နှိပ်နိုင်သည်)။  အက်ပ်ကိုထည့်သွင်းရန် (အက်ပ်ကိုထည့်သွင်းရန်
              မီနူးကိုနှိပ်ပါ။) ဤနည်းဖြင့် PPF4 အက်ပ်သည် မိုဘိုင်းစခရင် (Smart
              Phone) ပေါ်တွင် ပေါ်လာမည်ဖြစ်ပြီး၊ နောက်တစ်ကြိမ်တွင်
              အသုံးပြုရအဆင်ပြေစေမည်ဖြစ်သည်။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">2</span>
              <br />
              <br />
              สำหรับการใช้งานครั้งแรก ให้กดคลิกไปที่เมนูสมัครสมาชิก
              โดยให้กรอกข้อมูลชื่อ / เบอร์มือถือ / ผู้แนะนำ ( ถ้ามี )
              และกำหนดรหัสผ่านจำนวน 6 ตัว (ควรมีอักษรพิมพ์เล็กใหญ่ผสมกับตัวเลขและอักษรพิเศษ)
              เพื่อเพิ่มความปลอดภัยยิ่งขึ้น หลังจากนั้นกดคลิกปุ่มสมัครสมาชิก
              <br />
              <br />
              <div className="yellow p5">
              ပထမအကြိမ်အသုံးပြုခြင်း။  အဖွဲ့ဝင်ခြင်းမီနူးသို့သွားရန် နှိပ်ပါ။ 
              သင့်အမည် / မိုဘိုင်းနံပါတ် / လွှဲပြောင်းပေးသူ (ရှိပါက) ဖြ...
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">3</span>
              <br />
              <br /> ย้อนกลับไปที่หน้าแรกเข้าระบบ Login <br />
              ให้ใส่เบอร์มือถือ ที่ใช้สมัคร พร้อมกรอกรหัสพาสเวิด (Password) ให้ถูกต้องทุกครั้ง หลังจากนั้นกดคลิกปุ่มเข้าสู่ระบบ
              <br />
              <br />
              <div className="yellow p5">
              အကောင့်ဝင်ရန် ပင်မစာမျက်နှာသို့ ပြန်သွားပါ။ မှတ်ပုံတင်ရန်
              အသုံးပြုသည့် မိုဘိုင်းဖုန်းနံပါတ်ကို ရိုက်ထည့်ကာ စကားဝှက်
              (Password) ကို အချိန်တိုင်း မှန်မှန်ကန်ကန် ရိုက်ထည့်ပါ။ 
              ပြီးနောက်၊ အကောင့်ဝင်ခလုတ်ကိုနှိပ်ပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">4</span>
              <br />
              <br />
              สำหรับการใช้งานครั้งแรก ให้คลิกไปที่เมนูโปรไฟล์ 
              ให้ไปตรวจสอบข้อมูลที่ใช้ ในการติดต่อกับ Admin
              ให้ถูกต้องรวมถึงให้กรอกข้อมูล บัญชีธนาคาร ของตนที่ใช้ในการสมัคร เล่นพร้อมรับเงินรางวัล
              <br />
              
              <br />
              <div className="yellow p5">
              ပထမအကြိမ်အသုံးပြုခြင်း။  ပရိုဖိုင်မီနူးသို့သွားရန် နှိပ်ပါ။ 
              စီမံခန့်ခွဲသူထံ ဆက်သွယ်ရန်အသုံးပြုသည့် အချက်အလက်များ
              မှန်ကန်ကြောင်း စစ်ဆေးကြည့်ရှုရန်၊ ကစားရန် မှတ်ပုံတင်ရန်နှင့်
              ဆုငွေလက်ခံရန်အတွက် အသုံးပြုသည့် သင်၏ဘဏ်အကောင့်အချက်အလက်ကို
              ဖြည့်သွင်းပါ။
              </div>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal1 show2 */}
      <Modal show={show2} onHide={handleClose2} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="font16">
              <span className="font-title-howto">วิธีการเติมเงิน</span>{" "}
              ငွေဖြည့်နည်း
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-howto">
            <p>
              <span className="circle">1</span>
              <br />
              <br />
              เข้าไปที่หน้าแรกกดคลิกไปที่เมนูเติมเงิน หลังจากนั้น
              ให้คัดลอกเลขบัญชีที่ปรากฎในแอปไปยังระบบแอปธนาคารเพื่อทำการโอนเงิน
              (โอนขั้นต่ำ 20.-)
              <br />
              <br />
              <div className="yellow p5">
              ပင်မစာမျက်နှာသို့သွားပြီး ငွေဖြည့်သွင်းမီနူးကို နှိပ်ပါ။ ထို့နောက်
              ငွေလွှဲရန်အတွက် အက်ပ်တွင် ပေါ်လာသည့် အကောင့်နံပါတ်ကို
              ဘဏ်အက်ပ်စနစ်သို့ ကူးယူပါ (အနည်းဆုံး လွှဲခ 20.-)။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">2</span>
              <br />
              <br />
              เมื่อโอนเงินสำเร็จแล้วให้ส่งหลักฐานการโอนเงิน (Pay in) ไปยัง Line
              Official เพื่อติดต่อกับทีมงาน Admin
              <br />
              <br />
              <div className="yellow p5">
              ငွေလွှဲပြီးသည်နှင့် Admin အဖွဲ့ထံ ဆက်သွယ်ရန် Line Official သို့
              ငွေလွှဲကြောင်း အထောက်အထား (ငွေပေးချေခြင်း) ကို ပေးပို့ပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">3</span>
              <br />
              <br />
              หลังจากนั้นทีมงาน Admin จะทำการตรวจสอบ
              และจะเติมเงินเครดิตให้ด่วนภายในเวลา 5 นาที
              <br />
              <br />
              <div className="yellow p5">
              ထို့နောက် Admin အဖွဲ့မှ စစ်ဆေးပေးမည်ဖြစ်ပါသည်။  ပြီးတော့ 5
              မိနစ်အတွင်း ခရက်ဒစ်ကို အမြန်ငွေဖြည့်ပေးပါလိမ့်မယ်။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">4</span>
              <br />
              <br />
              เมื่อได้รับแจ้งจากทีมงาน Admin ว่าเติมเงินเครดิตสำเร็จแล้ว
              สามารถเข้าไปดูยอดเงินได้ที่เครดิต ( สามารถกดปุ่ม Refresh ได้ )
              <br />
              <br />
              <div className="yellow p5">
              ခရက်ဒစ်ငွေဖြည့်သွင်းမှု အောင်မြင်ကြောင်း စီမံခန့်ခွဲသူအဖွဲ့ထံမှ
              အကြောင်းကြားချက်ကို လက်ခံရရှိသောအခ...
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">5</span>
              <br />
              <br />
              ทุกครั้งที่มีการเติมเงินเครดิตในการเล่น
              ต้องใช้บัญชีธนาคารที่ได้ใช้สมัครเท่านั้นในการโอนเงินเติมเครดิต (
              ห้ามใช้บัญชีอื่นในการโอนเด็ดขาด ) และเมื่อถูกรางวัล ทีมงาน Admin
              จะโอนเงินรางวัลไปให้ตามข้อมูลบัญชีธนาคารที่ใช้ในการสมัครเท่านั้น
              <br />
              <br />
              <div className="yellow p5">
              ကစားရန် ခရက်ဒစ်ထည့်လိုက်တိုင်း  လျှောက်ထားရာတွင် အသုံးပြုသည့်
              ဘဏ်အကောင့်ကိုသာ ငွေဖြည့်ခရက်ဒစ် လွှဲပြောင်းရန် အသုံးပြုရပါမည်။ 
              (ငွေလွှဲရန် အခြားအကောင့်ကို မသုံးပါနှင့်။) ဆုရရှိသောအခါ၊
              အက်ဒ်မင်အဖွဲ့သည် ဆုငွေများကို လျှောက်လွှာတွင်အသုံးပြုသည့်
              ဘဏ်အကောင့်အချက်အလက်သို့သာ လွှဲပေးမည်ဖြစ်သည်။
              </div>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal1 show3 */}
      <Modal show={show3} onHide={handleClose3} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="font16">
              <span className="font-title-howto">วิธีการถอนเงิน </span>{" "}
              ငွေထုတ်နည်း
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-howto">
            <p>
              <span className="circle">1</span>
              <br />
              <br />
              เข้าระบบ application ไปที่เมนูถอนเงิน
              <br />
              <br />
              <div className="yellow p5">
              လျှောက်လွှာစနစ်သို့ဝင်ရောက်ပြီး ငွေထုတ်သည့်မီနူးသို့သွားပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">2</span>
              <br />
              <br />
              ระบุจำนวนเงินที่ต้องการถอนเครดิต
              <br />
              <br />
              <div className="yellow p5">
              ခရက်ဒစ်ထုတ်ယူလိုသော ပမာဏကို သတ်မှတ်ပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">3</span>
              <br />
              <br />
              ตรวจสอบความถูกต้อง แล้วกดยืนยันการถอนเงิน
              <br />
              <br />
              <div className="yellow p5">
              မှန်ကန်ကြောင်း အတည်ပြုပြီး ငွေထုတ်ကြောင်း အတည်ပြုရန် နှိပ်ပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">4</span>
              <br />
              <br />
              รอระบบอนุมัติการถอนเงินประมาณ 10 นาที
              <br />
              <br />
              <div className="yellow p5">
              သင့်ငွေထုတ်ခြင်းကို စနစ်မှ အတည်ပြုရန် 10 မိနစ်ခန့် စောင့်ပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">5</span>
              <br />
              <br />
              เมื่อระบบ Report ว่าโอนเงินสำเร็จ ถือว่าขั้นตอน เสร็จสมบูรณ์
              <br />
              <br />
              <div className="yellow p5">
              ငွေလွှဲမှု အောင်မြင်ကြောင်း စနစ်က သတင်းပို့သောအခါ  ခြေလှမ်းများကို
              ကုသပေးသည်။ ပြီးပါပြီ။
              </div>
            </p>
          </div>
          <div className="box-howto">
            <p>
              <span className="circle">6</span>
              <br />
              <br />
              ผู้เล่นสามารถถอนเงินได้ที่ระบบ application
              ของธนาคารที่ผู้เล่นได้ใช้สมัคร
              <br />
              <br />
              <div className="yellow p5">
              ကစားသမားများသည် လျှောက်လွှာစနစ်မှ ငွေထုတ်ယူနိုင်သည်။
              ကစားသမားလျှောက်ထားသည့်ဘဏ်
              </div>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal1 show4 */}
      <Modal show={show4} onHide={handleClose4} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="font16">
              <span className="font-title-howto">วิธีการซื้อหวย </span>{" "}
              ထီထိုးနည်း
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-howto">
            <p>
              <span className="circle">1</span>
              <br />
              <br />
              เข้าระบบ application ไปที่เมนูแทงหวย
              <br />
              <br />
              <div className="yellow p5">
              လျှောက်လွှာသို့ဝင်ရောက်ပြီး ထီလောင်းကစားမီနူးသို့သွားပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">2</span>
              <br />
              <br />
              เลือกหมวดหวยที่ต้องการเล่นพร้อมอ่านกติกา
              <br />
              <br />
              <div className="yellow p5">
              သင်ကစားလိုသော ထီအမျိုးအစားကို ရွေးချယ်ပြီး စည်းကမ်းချက်များကို
              ဖတ်ရှုပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">3</span>
              <br />
              <br />
              ผู้เล่นสามารถเลือกเล่นได้ทุกหมวดที่ยังไม่หมด เวลาปิดรับแทง
              <br />
              <br />
              <div className="yellow p5">
              ကစားသမားများသည် မကုန်သေးသော အမျိုးအစားအားလုံးတွင် ကစားရန်
              ရွေးချယ်နိုင်သည်။ လောင်းကစားလက်ခံခြင်းအတွက် ပိတ်ချိန်
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">4</span>
              <br />
              <br />
              เลือกแทงเลขที่ต้องการพร้อมระบุจำนวนเงิน เครดิตที่ต้องการซื้อหวย
              เมื่อตรวจสอบแล้วถูกต้องให้กดยืนยัน
              <br />
              <br />
              <div className="yellow p5">
              လောင်းလိုသောနံပါတ်ကိုရွေးချယ်ပြီး ပမာဏကိုသတ်မှတ်ပါ။ ထီထိုးရန်
              အကြွေးလိုအပ်ပါသည်။  စိစစ်ပြီး မှန်ကန်ပါက အတည်ပြုမည်ကို နှိပ်ပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">5</span>
              <br />
              <br />
              เข้าระบบ application ไปที่เมนูโพยหวย<br /> เพื่อตรวจสอบความถูกต้อง
              <br />
              <br />
              <div className="yellow p5">
              လျှောက်လွှာကိုဝင်ရောက်ပြီးထီမီနူးကိုသွားပါ။ တိကျမှုကို စစ်ဆေးရန်။
              </div>
            </p>
          </div>
          <div className="box-howto">
            <p>
              <span className="circle">6</span>
              <br />
              <br />
              รอผลการออกรางวัลตามที่ระบุในแอป /
              กรณี <br />ที่ผู้เล่นถูกหวยระบบแอปจะโอนเงินรางวัล เข้าบัญชีธนาคารที่ลูกค้าใช้สมัคร แบบอัตโนมัติภายในระยะเวลาประมาณ
              15 นาที
              <br />
              <br />
              <div className="yellow p5">
              အက်ပ်တွင် သတ်မှတ်ထားသည့်အတိုင်း ထီပေါက်သည့်ရလဒ်ကို စောင့်ပါ /
              ကစားသမားသည် ထီပေါက်သည့်ကိစ္စတွင်၊ အက်ပ်စနစ်သည်
              ဝယ်ယူသူလျှောက်ထားရန် အသုံးပြုသည့် ဘဏ်အကောင့်သို့ 15
              မိနစ်ခန့်အတွင်း ဆုငွေများကို အလိုအလျောက်လွှဲပေးမည်ဖြစ်သည်။
              </div>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal1 show5 */}
      <Modal show={show5} onHide={handleClose5} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="font16">
              <span className="font-title-howto">คำถามที่พบบ่อย</span><br /><br />
              အမြဲမေးလေ့ရှိသောမေးခွန်းများ
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-howto">
            <p>
              <span className="circle">1</span>
              <br />
              <br />
              เติมเงินเครดิตแล้วไม่มีเงินเครดิตเข้าระบบ <br />
เกินระยะเวลาที่ระบบกำหนด ควรทำอย่างไร ? <br /><span className="red">✔</span> ติดต่อ Admin อีกครั้งเพื่อให้ตรวจสอบแก้ไข
              <br />
              <br />
              <div className="yellow p5">
              ခရက်ဒစ်ငွေဖြည့်ပြီး စနစ်ထဲသို့ ခရက်ဒစ်မထည့်ပါ။
စနစ်မှသတ်မှတ်ထားသောအချိန်ကာလကျော်လွန်သွားပါပြီ။ ဘာလုပ်သင့်လဲ။ <br />
<span className="red">✔</span>စစ်ဆေးပြီး ပြင်ရန် စီမံခန့်ခွဲသူကို ထပ်မံဆက်သွယ်ပါ။
</div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">2</span>
              <br />
              <br />
              เมื่อระบบ Report ว่าโอนเงินสำเร็จ แต่ผู้เล่นไม่พบยอดเงินใน application ธนาคารที่ผู้เล่นใช้สมัคร สาเหตุเกิดจากอะไร ? <br />
              <span className="red">✔</span>ปัญหากี่ยวกับระบบ application ธนาคาร ของผู้เล่นมักมีการปิดปรับปุรุงหรือส่วนใหญ่<br /> จะมีปัญหาในช่วงเวลา 00:00 น. - 01:30 <br />
              <span className="red">✔</span>ผู้เล่นเข้าระบบ application ธนาคารผิด <br />ไม่ตรงกับข้อมูลธนาคารที่ใช้ในการสมัคร
              <br />
              <br />
              <div className="yellow p5">
              ငွေလွှဲမှု အောင်မြင်ကြောင်း စနစ်က သတင်းပို့သောအခါ  ဒါပေမယ့် ကစားသမားက လျှောက်ထားတဲ့ ဘဏ်လျှောက်လွှာမှာ လက်ကျန်ငွေကို ကစားသမားက ရှာမတွေ့ပါဘူး။  အကြောင်းရင်းကဘာလဲ။ <br />
            
              <span className="red">✔</span> ဘဏ်လုပ်ငန်းလျှောက်လွှာစနစ်များတွင် ပြဿနာများကစားသမားများ၏ ပစ္စည်းများကို ပြုပြင်ထိန်းသိမ်းရန် သို့မဟုတ် အများစုမှာ ၎င်းတို့ကို မကြာခဏ ပိတ်ထားသည်။ 12:00 AM မှ 1:30 am ကြားတွင် ပြဿနာများရှိမည်။ <br />

              <span className="red">✔</span> ကစားသမားများသည် မှားယွင်းသောဘဏ်လျှောက်လွှာစနစ်သို့ ဝင်ရောက်ပါ။  အပလီကေးရှင်းတွင်အသုံးပြုသောဘဏ်အချက်အလက်နှင့် မကိုက်ညီပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">3</span>
              <br />
              <br />
              ผู้เล่นต้องการเปลี่ยนเบอร์โทรที่ใช้ในการสมัคร สามารถทำได้หรือไม่ ?<br />
              <span className="red">✔</span> แนะนำให้ผู้เล่นเริ่มสมัครใหม่ โดยเข้าระบบ  application ไปที่เมนูสมัครสมาชิก
              <br />
              <br />
              <div className="yellow p5">
              ကစားသမားသည် မှတ်ပုံတင်ရန် အသုံးပြုသည့် ဖုန်းနံပါတ်ကို ပြောင်းလဲလိုပါသည်။ လုပ်ဆောင်နိုင်ပါသလား။ <br />
              <span className="red">✔</span> စနစ်သို့ဝင်ရောက်ခြင်းဖြင့် ထပ်မံလျှောက်ထားရန် ကစားသမားများအား အကြံပြုပါ။ အပလီကေးရှင်းသည် စာရင်းသွင်းမှုမီနူးသို့ သွားပါ။
              </div>
            </p>
          </div>

          <div className="box-howto">
            <p>
              <span className="circle">4</span>
              <br />
              <br />
              ผู้เล่นต้องการเปลี่ยนข้อมูลบัญชีธนาคารที่ใช้ใน การสมัครสามารถทำได้หรือไม่ ?<br />
              <span className="red">✔</span> แนะนำให้ผู้เล่นเริ่มสมัครใหม่ โดยเข้าระบบ  application ไปที่เมนูสมัครสมาชิก
              <br />
              <br />
              <div className="yellow p5">
              ကစားသမားသည် အသုံးပြုထားသော ဘဏ်အကောင့်အချက်အလက်ကို ပြောင်းလဲလိုပါသည်။ လျှောက်ထားရန် ဖြစ်နိုင်ပါသလား။ <br />
              <span className="red">✔</span> စနစ်သို့ဝင်ရောက်ခြင်းဖြင့် အပလီကေးရှင်းအသစ်တစ်ခုစတင်ရန် ကစားသမားများအား အကြံပြုပါ။ အပလီကေးရှင်းသည် စာရင်းသွင်းမှုမီနူးသို့ သွားပါ။
              </div> 
            </p>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <BottomNav />
    </>
  );
}

export default HowTo;
