const arrThai = {
    "topup": "เติมเงิน",
    "withdraw": "ถอนเงิน",
    "useBet": "ซื้อหวย",
    "getBet": "ถูกรางวัล",
};



export const getActivityTranslation = (activity) => {
    return arrThai[activity] || "Unknown Activity";
};

export const getAmountStyle = (activity) => {
    const isGreen = ["getBet"].includes(activity);
    const isRed = ["withdraw", "useBet"].includes(activity);
    const isYellow = ['topup'].includes(activity);
  
    if (isGreen) {
      return {assign: "+",color: "green-history", bgColor: "greenBg" }; // Green
    } else if (isRed) {
      return {assign: "-",color: "red-history", bgColor: "redBg" }; // Red
    } else if (isYellow) {
      return {assign: "+",color: "green-history", bgColor: "yellowBg" }; // Red{
    }else {
      return {color: "black-history" }; // Default color
    }
  };