import { apiSlice } from './apiSlice';
const USERS_URL = '/api/balances';

// const ACCESS_TOKEN = JSON.parse(sessionStorage.getItem('token'))
// console.log(ACCESS_TOKEN)

export const balancesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        total: builder.mutation({
            query: () => ({
                url: `${USERS_URL}/getBalance`,
                method: 'GET',
                credentials: 'include',
                

            })
        }),
        details: builder.mutation({
            query: () => ({
                url: `${USERS_URL}/getDetails`,
                method: 'GET',
                credentials: 'include',
            })
        })

    })
})

export const { useTotalMutation, useDetailsMutation} = balancesApiSlice;