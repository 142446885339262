import styled from 'styled-components';


export const Button = styled.button`
    width: 200px;
    height: 50px;
    background-color: red;

`

export const Input = styled.input`
    width: 45px;
    height: 45px;
    text-align: center;
    margin: 0px 5px;
    max-width: 45px;
    font-weight: bold;
    font-size: 1.6em;
    background: white;
    border: 1.2px solid rgb(85, 85, 85);
    border-radius: 5px;
    color: black;
    outline: none;
`;