import React from 'react'

const Forget0 = () => {
  return (
    <div>
    <div className="top-Register">
        <div className="title-Register">STEP 1</div>

    </div>
    <div className="box-center">
        <div className="box-info-Register">
            Your register phone number
        </div>
        <input type="tel" className="info-Register" placeholder='Your Phone Number' />
    </div>
  
    
</div>
  )
}

export default Forget0