import React, { useState } from 'react'

import { useSelector } from 'react-redux';
import AddBetForm from './AddBetForm';


const AddBetRates = ({closeModal}) => {

    const { bet0 } = useSelector((state) => state.bet);
    // console.log(bet0);
    const bet01 = [...bet0];
    // console.log(bet01);

  // const initialData = bet01;

  const [betData, setBetData] = useState(bet01);

  // Function to update a bet in the list
  const updateBets = (updatedBetData) => {
    setBetData(updatedBetData);
  };

  console.log(betData);
  return (
    <>
 <div>
      {/* <BetEditForm betData={betData} onUpdate={updateBets} /> */}
     <AddBetForm betData={betData} onUpdate={updateBets} closeModal={closeModal} />
    </div>
    </>
  )
}

export default AddBetRates
