import React, { useState } from "react";

import images from "../../components/helper/ImageImports";

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import ContainerLotto1 from "../../components/ContainerLotto1";
import ContainerLotto2 from "../../components/ContainerLotto2";
import ContainerLotto3 from "../../components/ContainerLotto3";
import BottomNav from "../../components/BottomNav";
// import EndMonthTime from '../../components/EndMonthTime'
// import EndMonth from '../../components/EndMonth'
import EndDay from "../../components/EndDay";
import EndDayTime from "../../components/EndDayTime";
import EndMonthTime1 from "../../components/EndMonthTime1";
import EndMonth1 from "../../components/EndMonth1";

function ListLotto() {
  // useLayoutEffect(()=> {
  //   window.scrollTo(0,0);
  // })

  const navigate = useNavigate();
  const onClickSubmit1 = (e) => {
    e.currentTarget.disabled = true;
    navigate("/playlotto/goldwing");
  };
  const onClickSubmit2 = () => navigate("/playlotto/huaystar");
  const onClickSubmit3 = () => navigate("/playlotto/huaycrown");
  const onClickSubmit4 = () => navigate("/playlotto/huaykaren");

  const onClickSubmit11 = () => navigate("/playlotto/cat");
  const onClickSubmit12 = () => navigate("/playlotto/dog");
  const onClickSubmit13 = () => navigate("/playlotto/cheetah");
  const onClickSubmit14 = () => navigate("/playlotto/penguin");

  const onClickSubmit21 = () => navigate("/playlotto/elephant");
  const onClickSubmit22 = () => navigate("/playlotto/bee");
  const onClickSubmit23 = () => navigate("/playlotto/fish");
  const onClickSubmit24 = () => navigate("/playlotto/monkey");

  const onClickSubmit31 = () => navigate("/playlotto/panda");
  const onClickSubmit32 = () => navigate("/playlotto/turtle");
  const onClickSubmit33 = () => navigate("/playlotto/bull");
  const onClickSubmit34 = () => navigate("/playlotto/cow");

  const onClickSubmit41 = () => navigate("/playlotto/fox");
  const onClickSubmit42 = () => navigate("/playlotto/hippopo");
  const onClickSubmit43 = () => navigate("/playlotto/ladybug");
  const onClickSubmit44 = () => navigate("/playlotto/bear");

  const onClickSubmit51 = () => navigate("/playlotto/dragon");
  const onClickSubmit52 = () => navigate("/playlotto/lion");
  const onClickSubmit53 = () => navigate("/playlotto/rhinoceros");
  const onClickSubmit54 = () => navigate("/playlotto/snake");

  const onClickSubmit61 = () => navigate("/playlotto/durian");
  const onClickSubmit62 = () => navigate("/playlotto/grape");
  const onClickSubmit63 = () => navigate("/playlotto/mangosteen");
  const onClickSubmit64 = () => navigate("/playlotto/orange");

  const onClickSubmit71 = () => navigate("/playlotto/watermelon");
  const onClickSubmit72 = () => navigate("/playlotto/banana");
  const onClickSubmit73 = () => navigate("/playlotto/greenapple");
  const onClickSubmit74 = () => navigate("/playlotto/mango");

  const onClickSubmit81 = () => navigate("/playlotto/papaya");
  const onClickSubmit82 = () => navigate("/playlotto/redapple");
  const onClickSubmit83 = () => navigate("/playlotto/cherry");
  const onClickSubmit84 = () => navigate("/playlotto/coconut");

  const onClickSubmit91 = () => navigate("/playlotto/lemon");
  const onClickSubmit92 = () => navigate("/playlotto/pineapple");
  const onClickSubmit93 = () => navigate("/playlotto/strawberry");
  const onClickSubmit94 = () => navigate("/playlotto/cake");

  const onClickSubmit101 = () => navigate("/playlotto/chocolate");
  const onClickSubmit102 = () => navigate("/playlotto/hamburger");
  const onClickSubmit103 = () => navigate("/playlotto/pizza");
  const onClickSubmit104 = () => navigate("/playlotto/sausage");

  const onClickSubmit111 = () => navigate("/playlotto/baseball");
  const onClickSubmit112 = () => navigate("/playlotto/basketball");
  const onClickSubmit113 = () => navigate("/playlotto/bowling");
  const onClickSubmit114 = () => navigate("/playlotto/football");

  const onClickSubmit121 = () => navigate("/playlotto/rugby");
  const onClickSubmit122 = () => navigate("/playlotto/equipment");
  const onClickSubmit123 = () => navigate("/playlotto/hammer");
  const onClickSubmit124 = () => navigate("/playlotto/pencil");

  const onClickSubmit131 = () => navigate("/playlotto/ruler");
  const onClickSubmit132 = () => navigate("/playlotto/scissors");
  const onClickSubmit133 = () => navigate("/playlotto/candlestick");
  const onClickSubmit134 = () => navigate("/playlotto/hanginglamp");

  const onClickSubmit141 = () => navigate("/playlotto/match");
  const onClickSubmit142 = () => navigate("/playlotto/tablelamp");
  const onClickSubmit143 = () => navigate("/playlotto/torch");
  const onClickSubmit144 = () => navigate("/playlotto/coin");

  const onClickSubmit151 = () => navigate("/playlotto/crown");
  const onClickSubmit152 = () => navigate("/playlotto/diamond");
  const onClickSubmit153 = () => navigate("/playlotto/gold");
  const onClickSubmit154 = () => navigate("/playlotto/treasurechest");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // const onClickSubmit = () => navigate('/playlotto')
  return (
    // <div className='main'>
    //   <div className="page">
    <>
      <div className="layout-lotto">
        <div className="relative-lotto">
          <ContainerLotto3
            srcImg={images.huaykaren}
            title="HUAY KAREN"
            handleShow={handleShow}
            onClickSubmit={onClickSubmit4}
            active={true}
            enddaytime={<EndDayTime hh={16} mm={0} ss={0} />}
            endday={<EndDay hh={16} mm={0} ss={0} />}
          />
          <ContainerLotto1
            srcImg={images.goldwing}
            title="GOLD WING"
            handleShow={handleShow1}
            onClickSubmit={onClickSubmit1}
            active={true}
            endmonthtime={<EndMonthTime1 dd={1} hh={15} mm={0} ss={0} />}
            endmonth={<EndMonth1 dd={1} hh={15} mm={0} ss={0} />}
          />
          <ContainerLotto1
            srcImg={images.huaystar}
            title="HUAY STAR"
            handleShow={handleShow2}
            onClickSubmit={onClickSubmit2}
            active={true}
            endmonthtime={<EndMonthTime1 dd={16} hh={15} mm={0} ss={0} />}
            endmonth={<EndMonth1 dd={16} hh={15} mm={0} ss={0} />}
          />
          <ContainerLotto1
            srcImg={images.huaycrown}
            title="HUAY CROWN"
            handleShow={handleShow3}
            onClickSubmit={onClickSubmit3}
            active={true}
            endmonthtime={<EndMonthTime1 dd={28} hh={15} mm={0} ss={0} />}
            endmonth={<EndMonth1 dd={28} hh={15} mm={0} ss={0} />}
          />

          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Woohoo, you're reading this text in a modal!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Modal1 heading1</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Woohoo, you're reading this text in a modal!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={show2} onHide={handleClose2} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Modal2 heading2</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Woohoo, you're reading this text in a modal!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={show3} onHide={handleClose3} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Modal3 heading3</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Woohoo, you're reading this text in a modal!
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose3}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <>
            <div className="box-btn-lotto">
              <div className="btn-play-lotto">CHARITY LOTTERY</div>
            </div>

            <div className="lottoHeight2"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.cat}
                  onClickSubmit={onClickSubmit11}
                />
                <ContainerLotto2
                  srcImg={images.dog}
                  onClickSubmit={onClickSubmit12}
                />
                <ContainerLotto2
                  srcImg={images.cheetah}
                  onClickSubmit={onClickSubmit13}
                />
                <ContainerLotto2
                  srcImg={images.penguin}
                  onClickSubmit={onClickSubmit14}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.elephant}
                  onClickSubmit={onClickSubmit21}
                />
                <ContainerLotto2
                  srcImg={images.bee}
                  onClickSubmit={onClickSubmit22}
                />
                <ContainerLotto2
                  srcImg={images.fish}
                  onClickSubmit={onClickSubmit23}
                />
                <ContainerLotto2
                  srcImg={images.monkey}
                  onClickSubmit={onClickSubmit24}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.panda}
                  onClickSubmit={onClickSubmit31}
                />
                <ContainerLotto2
                  srcImg={images.turtle}
                  onClickSubmit={onClickSubmit32}
                />
                <ContainerLotto2
                  srcImg={images.bull}
                  onClickSubmit={onClickSubmit33}
                />
                <ContainerLotto2
                  srcImg={images.cow}
                  onClickSubmit={onClickSubmit34}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.fox}
                  onClickSubmit={onClickSubmit41}
                />
                <ContainerLotto2
                  srcImg={images.hippopo}
                  onClickSubmit={onClickSubmit42}
                />
                <ContainerLotto2
                  srcImg={images.ladybug}
                  onClickSubmit={onClickSubmit43}
                />
                <ContainerLotto2
                  srcImg={images.bear}
                  onClickSubmit={onClickSubmit44}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.dragon}
                  onClickSubmit={onClickSubmit51}
                />
                <ContainerLotto2
                  srcImg={images.lion}
                  onClickSubmit={onClickSubmit52}
                />
                <ContainerLotto2
                  srcImg={images.rhinoceros}
                  onClickSubmit={onClickSubmit53}
                />
                <ContainerLotto2
                  srcImg={images.snake}
                  onClickSubmit={onClickSubmit54}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.durian}
                  onClickSubmit={onClickSubmit61}
                />
                <ContainerLotto2
                  srcImg={images.grape}
                  onClickSubmit={onClickSubmit62}
                />
                <ContainerLotto2
                  srcImg={images.mangosteen}
                  onClickSubmit={onClickSubmit63}
                />
                <ContainerLotto2
                  srcImg={images.orange}
                  onClickSubmit={onClickSubmit64}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.watermelon}
                  onClickSubmit={onClickSubmit71}
                />
                <ContainerLotto2
                  srcImg={images.banana}
                  onClickSubmit={onClickSubmit72}
                />
                <ContainerLotto2
                  srcImg={images.greenapple}
                  onClickSubmit={onClickSubmit73}
                />
                <ContainerLotto2
                  srcImg={images.mango}
                  onClickSubmit={onClickSubmit74}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.papaya}
                  onClickSubmit={onClickSubmit81}
                />
                <ContainerLotto2
                  srcImg={images.redapple}
                  onClickSubmit={onClickSubmit82}
                />
                <ContainerLotto2
                  srcImg={images.cherry}
                  onClickSubmit={onClickSubmit83}
                />
                <ContainerLotto2
                  srcImg={images.coconut}
                  onClickSubmit={onClickSubmit84}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.lemon}
                  onClickSubmit={onClickSubmit91}
                />
                <ContainerLotto2
                  srcImg={images.pineapple}
                  onClickSubmit={onClickSubmit92}
                />
                <ContainerLotto2
                  srcImg={images.strawberry}
                  onClickSubmit={onClickSubmit93}
                />
                <ContainerLotto2
                  srcImg={images.cake}
                  onClickSubmit={onClickSubmit94}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.chocolate}
                  onClickSubmit={onClickSubmit101}
                />
                <ContainerLotto2
                  srcImg={images.hamburger}
                  onClickSubmit={onClickSubmit102}
                />
                <ContainerLotto2
                  srcImg={images.pizza}
                  onClickSubmit={onClickSubmit103}
                />
                <ContainerLotto2
                  srcImg={images.sausage}
                  onClickSubmit={onClickSubmit104}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.baseball}
                  onClickSubmit={onClickSubmit111}
                />
                <ContainerLotto2
                  srcImg={images.basketball}
                  onClickSubmit={onClickSubmit112}
                />
                <ContainerLotto2
                  srcImg={images.bowling}
                  onClickSubmit={onClickSubmit113}
                />
                <ContainerLotto2
                  srcImg={images.football}
                  onClickSubmit={onClickSubmit114}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.rugby}
                  onClickSubmit={onClickSubmit121}
                />
                <ContainerLotto2
                  srcImg={images.equipment}
                  onClickSubmit={onClickSubmit122}
                />
                <ContainerLotto2
                  srcImg={images.hammer}
                  onClickSubmit={onClickSubmit123}
                />
                <ContainerLotto2
                  srcImg={images.pencil}
                  onClickSubmit={onClickSubmit124}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.ruler}
                  onClickSubmit={onClickSubmit131}
                />
                <ContainerLotto2
                  srcImg={images.scissors}
                  onClickSubmit={onClickSubmit132}
                />
                <ContainerLotto2
                  srcImg={images.candlestick}
                  onClickSubmit={onClickSubmit133}
                />
                <ContainerLotto2
                  srcImg={images.hanginglamp}
                  onClickSubmit={onClickSubmit134}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.match}
                  onClickSubmit={onClickSubmit141}
                />
                <ContainerLotto2
                  srcImg={images.tablelamp}
                  onClickSubmit={onClickSubmit142}
                />
                <ContainerLotto2
                  srcImg={images.torch}
                  onClickSubmit={onClickSubmit143}
                />
                <ContainerLotto2
                  srcImg={images.coin}
                  onClickSubmit={onClickSubmit144}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
          <>
            <div className="container-lotto">
              <div className="box-animal-lotto">
                <ContainerLotto2
                  srcImg={images.crown}
                  onClickSubmit={onClickSubmit151}
                />
                <ContainerLotto2
                  srcImg={images.diamond}
                  onClickSubmit={onClickSubmit152}
                />
                <ContainerLotto2
                  srcImg={images.gold}
                  onClickSubmit={onClickSubmit153}
                />
                <ContainerLotto2
                  srcImg={images.treasurechest}
                  onClickSubmit={onClickSubmit154}
                />
              </div>
            </div>
            <div className="lottoHeight1"></div>
          </>
        </div>
      </div>
      <BottomNav />
    </>
  );
}

export default ListLotto;
