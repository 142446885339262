import React from 'react'

// dd mean 0:sun 1:mon 2:tue 3:wed 4:thu 5:fri 6:sat
function EndDay({hh,mm,ss}) {
    const EndDay = getNextDayStartDate(hh,mm,ss)
    // console.log(EndDay)
    // Helper function to get the start date of the next week
    function getNextDayStartDate(hour,min,sec) {
      const today = new Date();
      const nextDayStartDate = new Date(today);
      nextDayStartDate.setHours(hour);
      nextDayStartDate.setMinutes(min);
      nextDayStartDate.setSeconds(sec);
      return nextDayStartDate;
    }
  return (
    <>
      {/* {EndDay.toLocaleDateString('th-TH')} <br /> */}
      {`หวยออกทุกวัน`}<br/>
      {EndDay.toLocaleTimeString('en-US',{ hour: 'numeric', minute: 'numeric', hour12: true })}
    </>
  )
}

export default EndDay
