import React from "react";

const ContainerLotto2 = ({srcImg, onClickSubmit}) => {
  return (
    <div>
      <img alt="" src={srcImg} className="charity" />
      <div className="box-btn-lotto">
        <div className="btn-play-lotto" onClick={onClickSubmit}>
          แทงหวย
        </div>
        <div className="btn-white-lotto font-size-12">ထီကစားပါ</div>
      </div>
    </div>
  );
};

export default ContainerLotto2;
