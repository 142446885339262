import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { delBet } from "../slices/betSlice";


const style = { color: "#c90303" };
// function for remove duplicate in array
// function removeDuplicates(arr) {
//   return [...new Set(arr)];
// }

const SideBar = () => {
  const dispatch = useDispatch();

  const { bet0 } = useSelector((state) => state.bet);

  const bet00 = [...bet0];
  console.log(bet00);

  const selectType2t1 = bet00.filter((item) => item.betType === "2t1");
  const selectType2b1 = bet00.filter((item) => item.betType === "2b1");
  const selectType2b0 = bet00.filter((item) => item.betType === "2b0");
  const selectType3t2 = bet00.filter((item) => item.betType === "3t2");
  const selectType3t1 = bet00.filter((item) => item.betType === "3t1");
  const selectType3t0 = bet00.filter((item) => item.betType === "3t0");

  const removeBet = (betType, betNum) => {
    const updatedBet = bet00.filter((bet) => !(bet.betType === betType && bet.betNum === betNum));
    dispatch(delBet(updatedBet))
  };

  // console.log(selectType2t0);

  // selectType2t1.map((item) => {
  //   console.log(item.betNum);
  // });

  // selectType2t1.forEach(item => {
  //     console.log(item.betNum)
  // })

  //   let allBetTypes = removeDuplicates(allBetType);
  //   console.log(allBetTypes);

  return (
    <>
      <div className="" style={{ display: "block", maxHeight: "699px" }}>
        <div className="box-numberlist-LottoPlay">
          <div
            className="box-post-LottoPlay flex-LottoPlay"
            style={{ paddingTop: "5px" }}
          >
            <div className="flex-1-LottoPlay" >
              {/*2 บน ตรง Start Here */}
              {selectType2t1.length !== 0 ? (
                <>
                  <div className="font-09-LottoPlay2 red-LottoPlay">
                    {`2 บน ตรง`}
                  </div>
                  {selectType2t1.map((item,index) => {
                    return (
                      <>
                     <div className="first" key={`type2t1_${index}`}>
                        <div className="flex-LottoPlay width100" >
                          <div className="number-area">
                            <div className="margin05-LottoPlay">
                              {item.betNum}
                            </div>
                            <div className="icon-number-area" onClick={() => removeBet(item.betType, item.betNum)}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className=""
                                style={style}
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      </>
                    );
                  })}
                  <div style={{ height: "10px" }}></div>
                </>
              ) : (
                <></>
              )}
              {/* End Here */}
              {/*2 ล่าง ตรง Start Here */}
              {selectType2b1.length !== 0 ? (
                <>
                
                  <div className="font-09-LottoPlay2 red-LottoPlay">
                    {`2 ล่าง ตรง`}
                  </div>
                  {selectType2b1.map((item, index) => {
                    return (
                      <>
                      <div className="second" key={`type2b1_${index}`}>
                        <div className="flex-LottoPlay width100" >
                          <div className="number-area">
                            <div className="margin05-LottoPlay">
                              {item.betNum}
                            </div>
                            <div className="icon-number-area" onClick={() => removeBet(item.betType, item.betNum)}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={style}
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      </>
                    );
                  })}
                  <div style={{ height: "10px" }}></div>
                </>
              ) : (
                <></>
              )}
              {/* End Here */}
              {/*2 ล่าง วิ่ง Start Here */}
              {selectType2b0.length !== 0 ? (
                <>
                  <div className="font-09-LottoPlay2 red-LottoPlay">
                    {`2 ล่าง วิ่ง`}
                  </div>
                  {selectType2b0.map((item, index) => {
                    return (
                      <>
                      <div className="third" key={`type2b0_${index}`}>
                        <div className="flex-LottoPlay width100" >
                          <div className="number-area">
                            <div className="margin05-LottoPlay">
                              {item.betNum}
                            </div>
                            <div className="icon-number-area" onClick={() => removeBet(item.betType, item.betNum)}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className=""
                                style={style}
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      </>
                    );
                  })}
                  <div style={{ height: "10px" }}></div>
                </>
              ) : (
                <></>
              )}
              {/* End Here */}
              {/*3 บน โต๊ด Start Here */}
              {selectType3t2.length !== 0 ? (
                <>
                  <div className="font-09-LottoPlay2 red-LottoPlay">
                    {`3 บน โต๊ด`}
                  </div>
                  {selectType3t2.map((item, index) => {
                    return (
                      <>
                      <div className="fourth" key={`type3t2_${index}`}>
                        <div className="flex-LottoPlay width100">
                          <div className="number-area">
                            <div className="margin05-LottoPlay">
                              {item.betNum}
                            </div>
                            <div className="icon-number-area" onClick={() => removeBet(item.betType, item.betNum)}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className=""
                                style={style}
                                
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      </>
                    );
                  })}
                  <div style={{ height: "10px" }}></div>
                </>
              ) : (
                <></>
              )}
              {/* End Here */}
              {/*3 บน ตรง Start Here */}
              {selectType3t1.length !== 0 ? (
                <>
                  <div className="font-09-LottoPlay2 red-LottoPlay">
                    {`3 บน ตรง`}
                  </div>
                  {selectType3t1.map((item, index) => {
                    return (
                      <>
                      <div className="fifth" key={`type3t1_${index}`}>
                        <div className="flex-LottoPlay width100">
                          <div className="number-area">
                            <div className="margin05-LottoPlay">
                              {item.betNum}
                            </div>
                            <div className="icon-number-area" onClick={() => removeBet(item.betType, item.betNum)}>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className=""
                                style={style}
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      </>
                    );
                  })}
                  <div style={{ height: "10px" }}></div>
                </>
              ) : (
                <></>
              )}
              {/* End Here */}

              {/*3 บน วิ่ง Start Here */}
              {selectType3t0.length !== 0 ? (
                <>
                  <div className="font-09-LottoPlay2 red-LottoPlay">
                    {`3 บน วิ่ง`}
                  </div>
                  {selectType3t0.map((item, index) => {
                    return (
                      <>
                        <div className="sixth" key={`type3t0_${index}`}>
                          <div className="flex-LottoPlay width100">
                            <div className="number-area">
                              <div className="margin05-LottoPlay">
                                {item.betNum}
                              </div>
                              <div className="icon-number-area" onClick={() => removeBet(item.betType, item.betNum)}>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className=""
                                  style={style}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div style={{ height: "10px" }}></div>
                </>
              ) : (
                <></>
              )}
              {/* End Here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
