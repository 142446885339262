import { apiSlice } from "./apiSlice";
const LOTTOS_URL = '/api/lottos';

export const lottosApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        lastLotto: builder.mutation({
            query: (data) => ({
                url: `${LOTTOS_URL}/last`,
                method: 'GET',
                body: data,
                credentials: 'include'
            })
        }),
        lastLottoTen: builder.mutation({
            query: (data) => ({
                url: `${LOTTOS_URL}/last`,
                method: 'POST',
                body: data,
                credentials: 'include'
            })
        })
    })
})

export const { useLastLottoMutation, useLastLottoTenMutation } = lottosApiSlice;