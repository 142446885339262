import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  calculateTotalBetAmount,
  calculateWinAmount,
  sumBetAmount,
} from "./helper/HelperBet";
import images from "./helper/ImageImports";

const OrderListDetail = () => {
  const { itemParams } = useParams();
  // console.log(itemParams);
  const { bet2 } = useSelector((state) => state.bet);
  const bet11 = [...bet2] || null;
  // console.log(bet11)
  const foundItem =
    bet11.length > 0 ? bet11.find((item) => item._id === itemParams) : false;

  let categoryItem = foundItem.category;
  let upperCategory = categoryItem.toUpperCase();
  // let dd = foundItem.createdAt;
  let betMeItem = foundItem.betMe;
  let itemStatus = foundItem.status;
  // console.log(itemStatus);
  // console.log(categoryItem);
  // console.log(dd);
  // console.log(betMeItem);

const arr2 = {
  "2t1": "2 ตัว บน",
  "2b1": "2 ตัว ล่าง",
  "2b0": "2 ตัว วิ่ง ล่าง",
  "3t2": "3 ตัว โต๊ด บน",
  "3t1": "3 ตัว บน",
  "3t0": "3 ตัว วิ่ง บน",
};

  const betMeDetail = (betMeItem) =>
    betMeItem.map((item) => (
      <div className="alldetail" key={item._id}>
        <div className="box-detail-old">
          <div className="box-title-detail-old">
            <span className="title-orderdetail">{arr2[item.betType]}</span>
          </div>
        </div>
        <div className="detail-list-old">
          <div className="left-detail-list-old">
            <div className="number-detail-list-old">{item.betNum}</div>
          </div>
          <div className="right-detail-list-old">
            <div className="detail-price-list-old">
              ยอดเล่น {item.betAmount} ฿
            </div>

            {item.checkWin ? (
              <div className="win-detail-status-list-old">
                ถูกรางวัล {calculateWinAmount(item)} ฿
              </div>
            ) : (
              <div className="lose-detail-status-list-old">ไม่ถูกรางวัล</div>
            )}
          </div>
        </div>
      </div>
    ));

  const betMeDetail1 = (betMeItem) =>
    betMeItem.map((item) => (
      <div className="alldetail" key={item._id}>
        <div className="box-detail-old">
          <div className="box-title-detail-old">
            <span className="title-orderdetail">{arr2[item.betType]}</span>
          </div>
        </div>
        <div className="detail-list-old">
          <div className="left-detail-list-old">
            <div className="number-detail-list-old">{item.betNum}</div>
          </div>
          <div className="right-detail-list-old">
            <div className="detail-price-list-old">
              ยอดเล่น {item.betAmount} ฿
            </div>
            <div className="no-detail-status-list-old">ยังไม่ออกรางวัล</div>
          </div>
        </div>
      </div>
    ));

  // Function to check if itemParams is included and return foundItem.category

  // function findCategory(itemToFind) {
  //   const foundItem =
  //     bet11.length > 0
  //       ? bet11.find((item) => item._id === itemToFind)
  //       : false;

  //     console.log(foundItem)
  //     return foundItem.category || null;

  // }
  // console.log(findCategory(itemParams))

  function findDate(itemToFind) {
    const foundItem =
      bet11.length > 0 ? bet11.find((item) => item._id === itemToFind) : false;

    let dd = foundItem.createdAt;

    if (!(dd instanceof Date)) {
      dd = new Date(dd);
    }
    // return dd;

    if (dd) {
      const day = dd.getDate().toString().padStart(2, "0");
      const month = (dd.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = dd.getFullYear();

      const hours = dd.getHours().toString().padStart(2, "0");
      const minutes = dd.getMinutes().toString().padStart(2, "0");
      const seconds = dd.getSeconds().toString().padStart(2, "0");

      const formatted = `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;

      return formatted;
    } else {
      return null;
    }

    // console.log(foundItem)
  }
  // console.log(findDate(itemParams));

  // function sumBetAmount(itemToFind) {
  //   const foundItem = bet11.find((item) => item._id === itemToFind);
  //   if (foundItem) {
  //     const sum = foundItem.betMe
  //       .map((bet) => bet.betAmount)
  //       .reduce((acc, amount) => acc + amount, 0);
  //     return sum;
  //   }
  //   return 0;
  // }
  // console.log(sumBetAmount(itemParams));

  // function checkStatus(itemStatus){
  //   if(itemStatus){
  //     console.log(true)
  //   }else {
  //     console.log(false)
  //   }
  // }

  // checkStatus(itemStatus)

  return (
    <>
      <div className="layout-orderlist">
        <div className="relative">
          <div className="container-old">
            <div className="box-type-old">
              <div className="title-not-border">
              <img src={images[categoryItem]} alt="" className="logo-lotto" />
                <div className="font-order-detail-green">
                  <div style={{ fontWeight: "600",fontSize: "20px" }}>{upperCategory}</div>
                  <div style={{ fontSize: "12px" }}>{findDate(itemParams)}</div>
                </div>
              </div>
            </div>
            <div className="box-top-old">
              <div className="left-top-old">
                <div className="title-top-old">รายการที่</div>
                <div className="detail-top-old">{itemParams}</div>
              </div>
              <div className="right-top-old">
                <div className="flex-right-top">
                  <div className="text-right-top">
                    <div>ยอดแทง</div>
                    <div>ยอดได้</div>
                  </div>
                  <div className="num-right-top">
                    <div>{sumBetAmount(betMeItem)} ฿</div>
                    <div className="green-text">{calculateTotalBetAmount(betMeItem)} ฿</div>
                  </div>
                </div>
              </div>
            </div>

            {bet2 !== null ? (
              itemStatus ? (
                betMeDetail1(betMeItem)
              ) : (
                betMeDetail(betMeItem)
              )
            ) : (
              <>
                <h1>Don't Have Bet Detail</h1>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderListDetail;
