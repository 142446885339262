import React from "react";
import BottomNav from "../../components/BottomNav";
import { useSelector } from "react-redux";
import images from "../../components/helper/ImageImports";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";

const Report = () => {
  const { myOwner } = useSelector((state) => state.owner);
  console.log(myOwner[0].category);
  const { categoryParams } = useParams();
  console.log(categoryParams);
  const selectedImage = images[categoryParams];

  const upCategory = (categoryParams) => {
    if (categoryParams === "goldwing") {
      return "GOLD WING";
    } else if (categoryParams === "huaystar") {
      return "HUAY STAR";
    } else if (categoryParams === "huaycrown") {
      return "HUAY CROWN";
    } else if (categoryParams === "huaykaren") {
      return "HUAY KAREN";
    } else {
      return categoryParams.toUpperCase();
    }
  };

  // const selectedImage = images[categoryParams];
  return (
    <>
      <div className="layout-lotto">
        <div className="relative-lotto">
          <div className="container-owner">
            <div className="head-history">
              <div className="flex-1-history title-history_without_border">
                <div className="font-title-history font7">รายการบัญชี หมวด</div>
              </div>
            </div>

            <div className="box-owner">
              <div className="boxbox-history">
                <div className="inbox-history">
                  <div className="inbox-detail-report">
                    <div className="flex-item item21">
                      <img alt="" src={selectedImage} className="charity1" />
                    </div>

                    <div className="flex-item item22">
                      <div className="font12 ">
                        <p className="font7">{`${upCategory(categoryParams)}`}</p>
                        <p className="p5"><span className="font7y font12_400">START</span> : {`xx/xx/xxxx`}</p>
                        <p className="p5"><span className="font7y font12_400 p9">END</span> : {`xx/xx/xxxx`}</p>
                       
                       
                      </div>
                    </div>
                    <div className="flex-item item23">
                      <div className="font12">
                        <p className="font7">{`OWNER`}</p> 
                        <p className="p5">0xxxxxxxxxx</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-owner">
              <div className="boxbox-history">
                <div className="inbox-history">
                  <div className="inbox-detail-report1">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td><p className="font7 font12 fontcenter">ยอดแทงรวม</p> </td>
                          <td >xxxxxx บาท</td>
                        </tr>
                        <tr>
                          <td><p className="font7 font12 fontcenter">% ผู้แนะนำ</p> </td>
                          <td >xxxxxx บาท</td>
                        </tr>
                        <tr>
                          <td><p className="font7 font12 fontcenter">ต้นทุนดำเนินการ</p> </td>
                          <td >xxxxxx บาท</td>
                        </tr>
                        <tr>
                          <td><p className="font7 font12 fontcenter">ค่าธรรมเนียมโอน</p> </td>
                          <td >xxxxxx บาท/ต่างประเทศ</td>
                        </tr>
                        <tr>
                          <td><p className="font7 font12 fontcenter">คอมมิชชั่น</p> </td>
                          <td>xxxxxx บาท  <span className="font7y font12_400">x %</span></td>
                        </tr>
                        
                      </tbody>
                    </Table>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="box-owner">
              <div className="boxbox-history">
                <div className="inbox-history">
                  <div className="inbox-detail-report1">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> <p className="font7 font12_400 fontcenter">รางวัล</p> </th>
                          <th><p className="font7 font12_400 fontcenter">จำนวนลูกค้า</p></th>
                          <th><p className="font7 font12_400 fontcenter">เงินแทง</p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><p className="font7y font12 fontcenter">3 บน ตรง</p> </td>
                          <td>xxx</td>
                          <td>xxx</td>
                        </tr>
                        <tr>
                          <td><p className="font7y font12 fontcenter">3 บน โต๊ด</p></td>
                          <td>xxx</td>
                          <td>xxx</td>
                        </tr>
                        <tr>
                          <td><p className="font7y font12 fontcenter">3 บน วิ่ง</p></td>
                          <td>xxx</td>
                          <td>xxx</td>
                        </tr>
                        <tr>
                          <td><p className="font7y font12 fontcenter">2 บน ตรง</p></td>
                          <td>xxx</td>
                          <td>xxx</td>
                        </tr>
                        <tr>
                          <td><p className="font7y font12 fontcenter">2 ล่าง ตรง</p></td>
                          <td>xxx</td>
                          <td>xxx</td>
                        </tr>
                        <tr>
                          <td><p className="font7y font12 fontcenter">2 ล่าง วิ่ง</p></td>
                          <td>xxx</td>
                          <td>xxx</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
};

export default Report;
