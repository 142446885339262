import { apiSlice } from "./apiSlice";

const BETS_URL = '/api/bets';

export const betsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addNewBet: builder.mutation({
            query: (data) => ({
                url: `${BETS_URL}/add`,
                method: 'POST',
                body: data,
                credentials: 'include',
                

            })
        }),
        myBet: builder.mutation({
            query: (data) => ({
                url: `${BETS_URL}/myBet`,
                method: 'GET',
                body: data,
                credentials: 'include'
            })
        })
    })
})

export const {useAddNewBetMutation, useMyBetMutation} = betsApiSlice;