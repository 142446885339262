import React from 'react'
import ppf25 from "../images/ppf25.png";
// import Timer2 from '../components/Timer2'
// import Timer from '../components/Timer'
// import EndWeekTime from '../components/EndWeekTime'

const ContainerLotto3 = ({srcImg, title, handleShow, onClickSubmit,active,enddaytime, endday}) => {
  return (
    <>
              <div className="container-lotto">
                <div className="box-text-lotto3">
                <div className="flex-lotto3">
                      <img alt="" className="flex-karean3" src={ppf25} />
                    </div>
                    </div>
                    <div className="box-text-lotto3">

                    <p className='text-karean'>ကရင်ထီအွန်လိုင်းမှ ရလဒ်များကို ၂၄ 
နာရီတိုင်း မဲနှိက်ပါသည်။</p>
                    </div>
                
                
                <div className="box-text-lotto">
                  <div className="box-title-lotto">
                    <div className="flag-lotto">
                      <img alt="" className="flag-karean" src={srcImg} />
                    </div>
                    <div className="title-lotto">
                      {title}
                      <div className="subtitle-lotto">{endday}</div>
                    </div>
                  </div>
                  <div className="box-countdown-lotto">
                    <div className="box-countdown-lotto-top">
                    {`เหลือเวลาทายผล`} <br />{enddaytime}
                     
                      {/* <Timer2 /> */}
                      {/* <Timer /> */}
                    </div>
                    <div className="box-countdown-lotto-bottom">ကျန်ရှိသောအချိန်</div>
                  </div>
                </div>
                <div className="box-btn-lotto1">
                  {/* <div> */}
                  <div className="btn-rule-lotto" onClick={handleShow}>
                    กติกา
                  </div>
                 <div className={active ? "btn-play-lotto":"btn-play-lotto disabled"}  onClick={onClickSubmit} aria-disabled={true} >แทงหวย</div>
                     
                </div>
                <div className="box-btn-lotto1">
                  <div className="btn-white-lotto" >
                    စည်းကမ်း
                  </div>
                  <div className="btn-white-lotto" >
                    ထီကစားပါ
                  </div>

                </div>
              </div>
              <div className="lottoHeight3"></div>
            </>
  )
}

export default ContainerLotto3
