import React from "react";
import { useSelector } from "react-redux";
import logoKbank from '../../images/logokbank.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";
function WithDraw() {
  const { userInfo } = useSelector((state) => state.auth);
  const { balance1 } = useSelector((state) => state.balance0);

  return (
    <>
      <div className="layout-main-Topup">
        <div className="relative-Topup">
          <div className="box-wallet-Topup">
          <p className="font-title"><span className="title-left-green">WITHDRAW</span>အကောင့်ထဲသို့ငွေထုတ်ယူပါ။ </p>
             <p className='ml5'>ถอนเงินเข้าบัญชี :</p> 
            
            <div className="container-box-Topup">
              <div className="box-icon-topup">
                <FontAwesomeIcon icon={faAddressCard} className="icons-Home" />
                {/* <FontAwesomeIcon icon={faUser} className="icons-Home" /> */}
                {/* <i className="fa fa-address-card" style="font-size: 32px;"></i> */}
              </div>
              <div>
                <div className="color-Topup">
                  Name : {userInfo ? userInfo.name : ""}
                </div>
                <div className="box-font08-Topup">
                  <div>{userInfo ? userInfo.phone : ""}</div>
                  <div>{balance1 ? balance1.balance : 0} ฿</div>
                  <br />
                  
              <h6><span className="title-history-left-nm">Bank Detail</span></h6>
            
                  
                  <div><Badge pilled bg="success" className="font12w mt25">Bank Name</Badge> {userInfo ? userInfo.bank.bankName : ""}</div>
                  <div>
                  <Badge pilled bg="success" className="font12w mt25">Bank Account</Badge> {userInfo ? userInfo.bank.bankAccount : ""}
                  </div>
                  <div>
                  <Badge pilled bg="success" className="font12w mt25">Bank Username</Badge> {userInfo ? userInfo.bank.bankUser : ""}
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div style={{ height: "330px" }}></div>
        <div className="container-txt-Topup">
            <p className="ml5 font16">အရေးကြီးသည်၊ ငွေမထုတ်မီဖတ်ပါ။</p>
            <p className="font-title"><span className="title-left-green font16w">สำคัญโปรดอ่านก่อนการถอนเงิน</span></p>
          <div className="margin-Topup">
          <div className="title-border">
            <span className="circle-yellow">1.</span>{" "}
            เข้าระบบ application ไปที่เมนูถอนเงิน<br />
            <br /> လျှောက်လွှာစနစ်သို့ဝင်ရောက်ပြီး ငွေထုတ်သည့်မီနူးသို့သွားပါ။
          </div>
          {/* <div style={{ fontSize: '16px' }}>2. ต้องโอนเงินภายในเวลาที่กำหนด และ กรอกจำนวนเงินถูกต้องตามที่กำหนดให้เท่านั้น <span style={{ color: 'red' }}>( หากเติมเงินด้วย QR Code จะไม่จำเป็นต้องกรอกทศนิยมด้วยตัวเอง )</span>
                        </div> */}
          <div className="title-border">
            <span className="circle-yellow">2.</span>{" "}
            ระบุจำนวนเงิน ที่ต้องการ ถอนเครดิต
            <br />
            <br />
            ခရက်ဒစ်ထုတ်ယူလိုသော ပမာဏကို သတ်မှတ်ပါ။
          </div>
          <div className="title-border">
            <span className="circle-yellow">3.</span>{" "} ตรวจสอบความถูกต้อง แล้วกดยืนยัน <br />การถอนเงิน<br />
            <br />
            မှန်ကန်ကြောင်း အတည်ပြုပြီး ငွေထုတ်ကြောင်း အတည်ပြုရန် နှိပ်ပါ။
          </div>
          <div className="title-border">
            <span className="circle-yellow">4.</span>{" "}
            รอระบบอนุมัติ การถอนเงิน ประมาณ 10 นาที<br />
            <br />
            သင့်ငွေထုတ်ခြင်းကို စနစ်မှ အတည်ပြုရန် 10 မိနစ်ခန့် စောင့်ပါ။
          </div>
          <div className="title-border">
            <span className="circle-yellow">5.</span>{" "}
            เมื่อระบบ Report ว่าโอนเงินสำเร็จ <br /> ถือว่าขั้นตอน เสร็จสมบูรณ์<br />
            <br />
            ငွေလွှဲမှု အောင်မြင်ကြောင်း စနစ်က သတင်းပို့သောအခါ  ခြေလှမ်းများကို ကုသပေးသည်။ ပြီးပါပြီ။
          </div>
          <div className="title-border">
            <span className="circle-yellow">6.</span>{" "} ผู้เล่นสามารถถอนเงินได้ที่ระบบ application ของธนาคารที่ผู้เล่นได้ใช้สมัคร<br />
            <br />
            ကစားသမားများသည် လျှောက်လွှာစနစ်မှ ငွေထုတ်ယူနိုင်သည်။ ကစားသမားလျှောက်ထားသည့်ဘဏ်
          </div>
        </div>
        </div>
        <div style={{ height: "130px" }}></div>

        
      </div>
    </>
  );
}


export default WithDraw;
